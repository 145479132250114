<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">
            <h2 class="playfair-display-font" style="color: white;">Hablemos</h2>
        </div>
    </div>
   
</div>

<app-how-to-apply></app-how-to-apply> <!-- lista para aplicar en campus emprendedor -->

<div class="contact-area ptb-100">
    <div class="container">
        
      
        <div class="row">
            <div class="col-lg-6 col-md-12">
                
                <div class="contact-info">
                    <span class="sub-title"></span>
                    
                    <p>Apreciamos mucho que te hayas tomado el tiempo para ponerte en contacto con nosotros. Tu mensaje es importante y nos esforzamos por responder a todas las consultas de manera oportuna. </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dirección</h3>
                            <p>Prolongación Calle Victoria No. 10, Sanctorum, Cuautlancingo, Puebla - México. </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contacto</h3>
                            <p>WhatsApp: <a href="tel:+522220080080">(+52) - 222000 - 0080</a></p>
                            <p>Mail: <a href="mailto:informes@campusem.com">informes@campusemp.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Horario de servicio</h3>
                            <p>Lunes - viernes: 09:00 - 19:00</p>
                            <p>Sábado: 09:00 - 16:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>¡Gracias de nuevo por tu interés en Campus Emprendedor!</h2>
                    <p>Su dirección de correo electrónico no será publicada. Los campos obligatorios están marcados *</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name -" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nombre">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nombre completo.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Correo electrónico">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Dirección de correo.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Número de contacto">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Número de teléfono.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Escribe tu mensaje..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Asunto.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Enviar<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d942.5094284697633!2d-98.2449512304861!3d19.10600136611146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc4226bb4a75f%3A0x3fe88253aa832923!2sC.%20Victoria%2067%2C%20Los%20Pinos%2C%2072713%20Sanctorum%2C%20Pue.!5e0!3m2!1ses-419!2smx!4v1704875054470!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>