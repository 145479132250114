import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-bloque-implementacion',
    templateUrl: './courses-bloque-implementacion.component.html',
    styleUrls: ['./courses-bloque-implementacion.component.scss']
})
export class CourseBloqueImplementacion implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
