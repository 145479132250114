import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-bloque-mdigital',
    templateUrl: './courses-bloque-mdigital.component.html',
    styleUrls: ['./courses-bloque-mdigital.component.scss']
})
export class CourseBloqueDigital implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
