<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/campus/index/logoce.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <!--
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                
                <form class="search-box">
                    <input type="text" class="input-search" placeholder="Search for anything">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form>
                
                <ul class="navbar-nav">
                    
                    <li class="nav-item">
                        <a href="/" class="nav-link">CE<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/acerca_campus" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Acerca Campus</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aviso de privacidad</a></li>
                            <li class="nav-item"><a routerLink="/times-idea-negocio" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Times</a></li>
                        </ul>
                    </li>
                    
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Entornos <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">

                            <li class="nav-item"><a routerLink="/entorno-idea-de-negocio" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">IDEAPRENEURS</a></li>
                            <li class="nav-item"><a routerLink="/entorno-autoempleo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">SOLOPRENEURS</a></li>
                            <li class="nav-item"><a routerLink="/entorno-inversion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">INVERPRENEURS</a></li>
                            
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a href="/forma-contacto" class="nav-link">Alianzas </a>
                        
                        <ul class="dropdown-menu">

                            <li class="nav-item"><a routerLink="/alianza-ip" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Iniciativa Privada</a></li>
                            <li class="nav-item"><a routerLink="/alianza-gobierno" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sector Público</a></li>
                            <li class="nav-item"><a routerLink="/alianza-ong" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ONG</a></li>
                         </ul>
                         
                    </li>

                    
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Eventos <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Conoce CE</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Taller Ideación</a></li>
                        </ul>
                    </li>
                    
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                       
                    </li>
                    
                </ul>
                <ul class="navbar-nav for-responsive">
                    
                    <li class="nav-item">
                        <a href="/" class="nav-link">Home CE<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/acerca_campus" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Acerca Campus</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Avisio de privacidad</a></li>



                        </ul>
                    </li>
                    
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Entornos <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">

                            <li class="nav-item"><a routerLink="/entorno-de-idea-negocio" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">IDEAPRENEURS</a></li>
                            <li class="nav-item"><a routerLink="/entorno-autoempleo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">SOLOPRENEURS</a></li>
                            <li class="nav-item"><a routerLink="/entorno-inversion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">INVERPRENEURS</a></li>
                            
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="/forma-contacto" class="nav-link">Alianzas </a>
                        
                        <ul class="dropdown-menu">

                            <li class="nav-item"><a routerLink="/alianza-ip" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Iniciativa privada</a></li>
                            <li class="nav-item"><a routerLink="/alianza-gobierno" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Sector público</a></li>
                            <li class="nav-item"><a routerLink="/entorno-ong" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">ONG</a></li>
                           
                        </ul>
                        
                    </li>

                    
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">Eventos <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Conoce CE</a></li>
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Taller Ideación</a></li>
                        </ul>
                    </li>
                    
                    
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                        
                    </li>
                    
                </ul>
            </div>
        
            <div class="others-option d-flex align-items-center">
                
                <div class="option-item">
                    <a routerLink="/forma-contacto" class="default-btn"><i class="flaticon-user"></i>Hablemos<span></span></a>
                </div>
            </div>
            -->
        </nav>
    </div>
</div>
