<div class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Dirección General</span>
            <h2>Creadores del Campus</h2>
            <p>Ellos son los creadores de Campus Emprendedor, galardonados con el Premio Nacional del Emprendedor,
            cuya misión es acercar las herramientas clave de formación empresarial a nivel global</p>
        </div>
        <div class="advisor-slides">
            <owl-carousel-o [options]="advisorSlides">
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/campus/acerca/julio_2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">Ing. Julio González Jiménez</a></h3>
                                    <span class="sub-title">Director Campus Emprendedor</span>
                                    <p>El éxito es el resultado de una travesía que se construye con innumerables metas alcanzadas y que toma forma a
                                    través de los valiosos aprendizajes que nos brindan los fracasos</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/campus/acerca/ernesto_2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">Ing. Ernesto González Jiménez</a></h3>
                                    <span class="sub-title">Subdirector Campus Emprendedor</span>
                                    <p>Emprender marca el inicio de un camino hacia el éxito, un trayecto que está lleno de riesgos y desafíos,
                                    pero también de lecciones y aprendizajes valiosos.
                                    mientras avanzamos sin cesar</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img3.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">James Andy</a></h3>
                                    <span class="sub-title">QA Project Expert</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                                    <ul class="social-link">
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                 -->
            </owl-carousel-o>
        </div>
    </div>
</div>
