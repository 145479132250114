import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-bloque-marketing',
    templateUrl: './courses-bloque-marketing.component.html',
    styleUrls: ['./courses-bloque-marketing.component.scss']
})
export class CourseBloqueMarketing implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
