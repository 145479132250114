<div class="row align-items-center">
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/sedeco.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/logo_CAF.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/tlaxcala.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/robsys.png" alt="image">
        </div>
    </div>

    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/logo_lif.png" alt="image">
        </div>
    </div>

     <div class="col-lg-2 col-6 col-sm-3 col-md-3">
         <div class="single-partner-item">
             <img src="assets/img/campus/index/logo_BID.png" alt="image">
         </div>

    </div>

    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/cepal.png" alt="image">
        </div>
    </div>

    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/idx.png" alt="image">
        </div>
    </div>

    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/campus/index/liga_empresarios.png" alt="image">
        </div>
    </div>
     
</div>
