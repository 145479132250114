<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">

            <h2 class="playfair-display-font" style="color: white;">Entornos de Emprendimiento <br> Formulario de Registro </h2>
        </div>
    </div>

</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>Registro</h2>
                    <form>
                        <div class="form-group">
                            <label>Nombre completo </label>
                            <input type="text" class="form-control" placeholder="Username or email">
                        </div>
                        <div class="form-group">
                            <label>Correo</label>
                            <input type="email" class="form-control" placeholder="Username or email">
                        </div>
                        <!--
                        <div class="form-group">
                            <label>Nombre de Usuario</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                        -->
                        <!-- Dropdown field for selecting the entrepreneurship environment -->
                        <div class="form-group">
                            <label for="entrepreneurship-environment">Entornos de Emprendimiento</label>
                            <select class="form-control" id="entrepreneurship-environment">
                                <option value="IDEAPRENEUR">IDEAPRENEUR</option>
                                <option value="SOLOPRENEUR">SOLOPRENEUR</option>
                                <option value="INVERPRENEUR">INVERPRENEUR</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Nombre de la Idea de Negocio</label>
                            <input type="text" class="form-control" placeholder="Nombre de la Idea">
                        </div>
                        <div class="form-group">
                            <label>Descripción de la Idea</label>
                            <textarea class="form-control" placeholder="Describa su idea de negocio aquí"></textarea>
                        </div>
                        <div class="form-group">
                            <label>¿Por qué es Innovadora?</label>
                            <textarea class="form-control" placeholder="Explique por qué su idea es innovadora"></textarea>
                        </div>
                        <div class="form-group">
                        <label>Estado</label>
                        <select class="form-control">
                            <option value="Aguascalientes">Aguascalientes</option>
                            <option value="Baja California">Baja California</option>
                            <option value="Baja California Sur">Baja California Sur</option>
                            <option value="Campeche">Campeche</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="Ciudad de México">Ciudad de México</option>
                            <option value="Coahuila">Coahuila</option>
                            <option value="Colima">Colima</option>
                            <option value="Durango">Durango</option>
                            <option value="Estado de México">Estado de México</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="Michoacán">Michoacán</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">San Luis Potosí</option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz">Veracruz</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                        </select>
                        </div>
                        <!-- <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p> -->
                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </div>





            <div class="col-lg-6 col-md-12">

                <div class="login-form">
                    <img src="assets/img/campus/index/banner_ce_545.png" alt="Login Image" style="width: 100%; height: auto;">
                    <!--
                    <h2>Login</h2>
                    <form>

                        <div class="form-group">
                            <label>Username or email</label>
                            <input type="text" class="form-control" placeholder="Username or email">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div>
                        <button type="submit">Log In</button>
                    </form>
                    -->
                </div>

            </div>



        </div>
    </div>
</div>
