<div class="information-area ptb-20">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title"></span>
                    <h2></h2>
                    <ul class="apply-details">
                        <li>
                            <div class="icon">
                                <i class="flaticon-checkmark"></i>
                            </div>
                            <h3>Identificación de Necesidades</h3>
                            <p>
                                En esta etapa inicial, nuestro objetivo es comprender a fondo las necesidades específicas 
                                de nuestros socios potenciales. Realizamos un análisis detallado para identificar los desafíos
                                 únicos que enfrentan y las metas que aspiran a alcanzar a través de la alianza.
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-webinar"></i>
                            </div>
                            <h3>Adaptación y Tropicalización de Modelos de Formación</h3>
                            <p>
                                Con las necesidades claramente definidas, procedemos a adaptar nuestros programas de formación 
                                para que se alineen con los objetivos específicos de nuestros aliados. Integración de tecnologías educativas 
                                y técnicas de aprendizaje que faciliten la absorción de conocimientos de manera eficiente y efectiva.
                            </p>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title"></span>
                    <h2></h2>
                    <ul class="apply-details">
                        
                        <li>
                            <div class="icon">
                                <i class="flaticon-credit-card-1"></i>
                            </div>
                            <h3>Accesibilidad y Precios Especiales</h3>
                            <p>
                                Entendemos la importancia de la accesibilidad, por lo que ofrecemos condiciones especiales
                                 para nuestros socios, por medio de convenios de participación que 
                                 proporcionan acceso a tarifas preferenciales para miembros de la comunidad.
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Costos Especiales y Acceso a Plataforma</h3>
                            <p>
                                Finalmente, ofrecemos un modelo de costos transparente y especializado que refleja el valor 
                                de la alianza con una estructura de precios diseñada para ofrecer el mejor retorno de inversión 
                                y maximizar el impacto del programa de formación.
                            </p>
                        </li>
                    </ul>
                </div>
                <!--
                <div class="information-image text-center">
                
                   <img src="assets/img/campus/index/aplicar_ce.png" alt="image">
                </div>
                -->
            </div>
        </div>
    </div>
</div>
