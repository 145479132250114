<div class="program-area ptb-60"> <!-- *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});"-->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="program-section-title">
                    <h2 class="playfair-display-font">Forjando Alianzas Estratégicas: <br> Unamos Fuerzas para mejorar el Ecosistema Emprendedor</h2>
                    <p>En <strong> Campus Emprendedor</strong>, creemos firmemente en el poder de la colaboración. Estamos en la búsqueda activa de alianzas estratégicas con 
                        organizaciones visionarias como la tuya. Ya sea que formes parte de una ONG, una iniciativa privada o una entidad 
                        gubernamental, tenemos oportunidades únicas de colaboración que pueden enriquecer tanto a tu comunidad como a la nuestra.
                        <br><br>
                        El Ecosistema del Campus Emprendedor es un modelo dinámico diseñado para catalizar
                        el crecimiento y el desarrollo de ideas
                        emprendedoras, convirtiéndolas en negocios
                        exitosos y sostenibles. En su núcleo, se encuentra
                        el “Thinker”  el emprendedor, innovador,
                        o estudiante quien es el motor y el
                        beneficiario principal de este ecosistema.
                        <br><br>
                        El Thinker interactúa con la metodología con
                        cada pilar, recibiendo apoyo, conocimiento
                        y evaluando su entorno, las alianzas con diversos
                        sectores enriquecen la experiencia del
                        Thinker, ofreciendo una visión más amplia y
                        recursos adicionales. El ecosistema opera de
                        manera cíclica, donde los logros y aprendizajes
                        de cada Thinker retroalimentan y enriquecen
                        continuamente el campus, beneficiando a
                        futuros emprendedores.
                    </p>
                    <a routerLink="/forma-contacto" class="default-btn"><i class="flaticon-user"></i>Hagamos alianza<span></span></a> 
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="program-list">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-program-box">
                                <!-- <div class="shape"><img src="assets/img/list-shape1.png" alt="image"></div> -->
                                <div class="icon">
                                    <!-- <i class="flaticon-diet"></i> -->
                                    <img src="assets/img/campus/index/empresas-cir.png" alt="Alianzas Iniciativa Privada">
                                </div>
                                <h3 class="playfair-display-font"><a routerLink="/">Iniciativa Privada</a></h3>
                                <p>Impulsemos la innovación y negocios sustentables de nuestros asociados maximizando nuestros recursos. </p>
                                
                            </div>
                            <div class="single-program-box">
                                <!-- <div class="shape"><img src="assets/img/list-shape2.png" alt="image"></div> -->
                                <div class="icon">
                                    <!-- <i class="flaticon-healthy-food"></i> -->
                                    <img src="assets/img/campus/index/ong-cir.png" alt="Alianzas Organizaciones Civiles">
                                </div>
                                <h3 class="playfair-display-font"><a routerLink="/alianza-ong">ONG</a></h3>
                                <p>Amplía tu impacto social y alcance comunitario a través de nuestros recursos de formación empresarial.</p>
                                
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-program-box">
                                <!-- <div class="shape"><img src="assets/img/list-shape3.png" alt="image"></div> -->
                                <div class="icon">
                                    <!-- <i class="flaticon-pineapple"></i> -->
                                    <img src="assets/img/campus/index/gobierno-cir.png" alt="Alianzas Gobiernos - Sector Público">

                                </div>
                                <h3 class="playfair-display-font"><a routerLink="/about-1">Gobiernos</a></h3>
                                <p>Fortalece los ecosistemas empresariales locales y fomenta la innovación para fortalecer políticas públicas.</p>
                                <!--<a routerLink="/about-1" class="link-btn">Hagamos alianza</a>-->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="program-circle-shape"><img src="assets/img/circle-shape.png" alt="image"></div> -->
                </div>
            </div>
        </div>
    </div>
</div>