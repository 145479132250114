<div class="our-story-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number"></span> Que ofrecemos</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        En Campus Emprendedor, creemos en la colaboración como el motor del éxito empresarial. Trabajamos
                        junto a empresas privadas para impulsar la innovación, fomentar el crecimiento económico y fortalecer
                        el tejido empresarial. Nuestra experiencia, enfoque tecnológico y compromiso con las mejores
                        prácticas empresariales nos convierten en el socio ideal para potenciar juntos el éxito de su
                        empresa. Juntos, creamos oportunidades y construimos el futuro empresarial
  
                    </p>
                   
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">1</span> Nearshoring</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Nuestra plataforma de análisis vincula información de datos abiertos con  datos cruciales sobre el mercado objetivo, tendencias del consumidor y análisis de competidores, permitiendo a las empresas tomar decisiones informadas y ágiles. Además, nuestra red de expertos y colaboradores locales brinda conocimiento sobre regulaciones, prácticas comerciales y recursos de talento en la región de nearshoring. Así, ayudamos a las empresas a minimizar riesgos y maximizar oportunidades en su expansión regional, asegurando un crecimiento exitoso y sostenible en un nuevo mercado.
                    </p>

                </div>
            </div>
            <!--
            <div class="col-lg-12 col-md-12">
                <div class="our-story-image">
                    <img src="assets/img/story-img.jpg" alt="image">
                </div>
            </div>
            -->
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">2</span> Rutas de Desarrollo e Innovación</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Buscamos establecer el conocimiento como el motor principal de la logística del know how y la innovación. Nuestro objetivo es forjar relaciones y colaboraciones con escuelas, empresas y proyectos que conviertan la adquisición de conocimiento en una fuente de inspiración para la innovación y el desarrollo económico. Creemos que, al hacerlo, podemos impulsar una nueva era de crecimiento empresarial y prosperidad.

                    </p>

                    
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">3</span>Transformación Digital</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Campus Emprendedor desempeña un papel crucial al ayudar a establecer el ADN empresarial necesario para la adopción de prácticas tecnológicas. Facilitamos la incorporación de herramientas tecnológicas en la cultura empresarial, lo que permite a las organizaciones seguir avanzando en esta carrera constante contra la tecnología y aprovechar al máximo las oportunidades que ofrece.

                    </p>
                   
                </div>
            </div>
        </div>
    </div>
</div>
