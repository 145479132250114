<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">
            <h2 class="playfair-display-font" style="color: white;">Terminos del Servicio</h2>
        </div>
    </div>
   
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="terms-of-service-content">
                    <img src="assets/img/campus/terminos_servicio.png" alt="Terminos del Servicio">
                    <p><i>Estos Terminos de Servicio fueron actualizados por última vez el 1 de enero de 2024</i></p>
                    <h3>1. Nuestro website</h3>
                    <p>Dirección de nuestro website: http://campusemp.com</p>
                    <blockquote class="blockquote">
                        <p>Bienvenido a Campus Emprendedor, una plataforma dedicada a proporcionar educación y herramientas digitales para emprendedores. Nuestro objetivo es ofrecer recursos que impulsen el crecimiento y desarrollo de proyectos innovadores.</p>
                    </blockquote>
                    
                    <h3>2. Aceptación de los Términos</h3>
                    <p>Al acceder y utilizar nuestro sitio web y servicios, usted reconoce que ha leído, entendido y aceptado estar vinculado por estos Términos del Servicio. Si no está de acuerdo con alguno de los términos y condiciones aquí establecidos, no deberá utilizar ni acceder a nuestros servicios.</p>
                   
                    <h3>3. Modificaciones a los Términos</h3>
                    <p>Nos reservamos el derecho de modificar o reemplazar estos Términos del Servicio en cualquier momento. Es su responsabilidad revisar periódicamente esta página para estar informado de cualquier cambio. Su uso continuado del servicio después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.</p>
                   
                    <h3>4. Descripción del Servicio</h3>
                    <p> Ofrecemos diversos programas de formación, acceso a herramientas digitales y eventos de networking, diseñados para satisfacer las necesidades de los emprendedores en diferentes etapas de su desarrollo empresarial.</p>

                    <h3>5. Registro y Cuenta de Usuario</h3>
                    <p>Para acceder a ciertas funciones de nuestro servicio, puede ser necesario que se registre y cree una cuenta. Usted es responsable de mantener la confidencialidad de su contraseña y cuenta, así como de todas las actividades que ocurran bajo su cuenta.</p>
                    
                    <h3>6. Privacidad y Protección de Datos</h3>
                    <p>Su privacidad es importante para nosotros. La recopilación y uso de información personal en  Campus Emprendedor Idx se rige por nuestro Aviso de Privacidad. Al aceptar estos Términos del Servicio, también reconoce haber leído y entendido nuestro Aviso de Privacidad.</p>
                
                    <h3>7. Conducta del Usuario</h3>
                    <p>Usted se compromete a utilizar nuestro servicio únicamente para fines legales y de manera que no infrinja los derechos de, restrinja o inhiba el uso y disfrute del servicio por parte de cualquier tercero. La conducta prohibida incluye acosar o causar molestias o inconvenientes a cualquier otra persona, transmitir contenido obsceno o ofensivo, o interrumpir el flujo normal de diálogo dentro de nuestro servicio.</p>
                    
                    <h3>8. Propiedad Intelectual</h3>
                    <p>Todos los derechos de propiedad intelectual en el sitio web, los servicios ofrecidos y todo el material publicado en él son propiedad de Campus Emprendedor Idx o de nuestros licenciantes. Estos trabajos están protegidos por leyes y tratados de derechos de autor en todo el mundo. Todos estos derechos están reservados.</p>
                
                    <h3>9. Uso de Herramientas Digitales</h3>
                    <p>Se les otorga acceso a herramientas digitales específicas para su formación y desarrollo empresarial. Estas herramientas no deben ser utilizadas para ningún propósito ilegal, ni para el desarrollo de proyectos que infrinjan los derechos de propiedad intelectual de terceros.</p>
                
                    <h3>10. Cancelación y Terminación</h3>
                    <p>Usted o Campus Emprendedor Idx pueden terminar este acuerdo en cualquier momento. Si usted incumple estos Términos del Servicio, su derecho a utilizar el servicio cesará inmediatamente.</p>
                
                    <h3>11. Limitación de Responsabilidad</h3>
                    <p>En ningún caso Campus Emprendedor Idx, ni sus directores, empleados o agentes, serán responsables por cualquier daño directo, indirecto, incidental, especial, consecuente o punitivo resultante de su uso o incapacidad de usar el servicio.</p>
                
                    <h3>12. Indemnización</h3>
                    <p>Usted acuerda indemnizar y eximir de responsabilidad a Campus Emprendedor Idx, sus directores, empleados y agentes, de y contra cualquier reclamación, daños, obligaciones, pérdidas, responsabilidades, costos o deudas, y gastos resultantes de su uso del servicio y/o violación de estos términos.</p>
                
                    <h3>13. Disputas</h3>
                    <p>Cualquier disputa relacionada con estos Términos del Servicio será regida e interpretada de acuerdo con las leyes del país o estado donde se encuentra Campus Emprendedor Idx, sin dar efecto a ningún principio de conflictos de leyes.</p>
                
                    <h3>14. Contacto</h3>
                    <p>Para cualquier pregunta o consulta sobre estos Términos del Servicio, por favor contáctenos en direccion@campusemp.com.</p>
                

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/acerca_campus">Acerca de CE</a></li>
                            <li><a routerLink="/aviso-privacidad">Aviso de Privacidad</a></li>
                            <li class="active"><a routerLink="/terminos-de-servicio">Terminos del Servicio</a></li>
                        </ul>
                    </section>
                    <!--
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                    -->
                </aside>
            </div>
        </div>
    </div>
</div>