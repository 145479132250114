<div class="health-coaching-feedback-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-feedback-image">
                    <img src="assets/img/campus/index/convocatoria_CIEN_png.png" alt="ProjectPreneur">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-inner">
                    <h2 class="playfair-display-font">Se parte de esta experiencia de gran valor, en tu proceso de formación empresarial.</h2>
                    <div class="feedback-quote">
                        <p>"Para Campus Emprendedor los emprendimiento tienen la fuerza del agua, siempre encuentra su camino. Ante cualquier obstáculo, fluye, se adapta y sigue adelante, creando su propia ruta hacia el éxito."</p>
                        <div class="client-info">
                            <div class="d-flex justify-content-center align-items-center">
                                <img src="assets/img/campus/acerca/julio_2.png" alt="Director general ">
                                <div class="title">
                                    <h3>Julio González</h3>
                                    <span>Director General Campus Emprendedor</span>
                                </div>
                            </div>
                           
                        </div>
                        <!--
                        <div class="object1"><img src="assets/img/object1.png" alt="image"></div>
                        <div class="object2"><img src="assets/img/object2.png" alt="image"></div>
                        <div class="object3"><img src="assets/img/object3.png" alt="image"></div>
                        <div class="object4"><img src="assets/img/object4.png" alt="image"></div>
                        -->
                    </div>
                    <br><a routerLink="/projectpreneur" class="default-btn">Registrate<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>