<div class="free-trial-area ptb-100 bg-fffaf3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="free-trial-image text-center">
                    <img src="assets/img/campus/index/mas_info.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="free-trial-form">
                    <span class="sub-title"> Acceso a prueba gratuita</span>
                    <h2>Conoce nuestra metodología Power For Thinker</h2>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Nombre *">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Número Telefónico *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Dudas y/o Comentarios *">
                        </div>
                        <button type="submit">Contactemos</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
