<div class="events-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="coaching-events-content">
                    <span class="sub-title">PROGRAMA CAMPUS EMPRENDEDOR</span>
                    <h2 class="playfair-display-font">Próximos Eventos 2024</h2>
                    <div class="single-coaching-events-box">
                        <span class="location"><i class='bx bx-map'></i> Programa </span>
                        <h3 class="inter-font"><a routerLink="/projectpreneur">ProjectPreneur Puebla </a></h3>
                        <a routerLink="/projectpreneur" class="default-btn"><i class="flaticon-user"></i> Registro<span></span></a>
                        <div class="date">
                            <div class="div">
                                20
                                <span>Nov </span>
                                
                            </div>
                        </div>
                    </div>
                    <div class="single-coaching-events-box">
                        <span class="location"><i class='bx bx-map'></i>Taller</span>
                        <h3 class="inter-font"><a routerLink="/">Propuesta de Valor </a></h3>
                        <a routerLink="/" class="default-btn"><i class="flaticon-user"></i> Registro<span></span></a>
                        <div class="date">
                            <div class="div">
                                07
                                <span>Dic</span>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div class="single-coaching-events-box">
                        <span class="location"><i class='bx bx-map'></i>Taller</span>
                        <h3 class="inter-font"><a routerLink="/">Conoce tu capacidad empresarial</a></h3>
                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Registro<span></span></a>
                        <div class="date">
                            <div class="div">
                                20
                                <span>Ene 2024</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="coaching-events-image">
                    <img src="assets/img/campus/index/imagen_eventos.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <!--<div class="c-shape5"><img src="assets/img/business-coach/c-shape5.png" alt="image"></div> -->
</div>
