<div class="container">
    <div class="section-title">
        <span class="sub-title">Desarrollo del Pensamiento Empresarial</span>
        <h2>Plataforma de Formación e Implementación de Ideas de Negocio </h2>
        <p>Campus Emprendedor es un innovador campus de formación y recursos, diseñado tanto para emprendedores
        como para personas que desean llevar sus ideas de negocios al siguiente nivel. Desarrollado por
        galardonados con el premio nacional del emprendedor 2017 en México, este campus tiene un modelo
        híbrido en un enfoque integral. Basado en un modelo de negocio exitoso, se ha transformado en un
        modelo de formación que guía a aquellos que buscan emprender o ya están en proceso.</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Metodología</h3>
                <p>Nuestra metodología Power For Thinkers
                    se centra en la transferencia eficaz de conocimiento,
                    especialmente diseñada para
                    Thinkers (emprendedores en desarrollo
                    de Campus Emprendedor) que buscan
                    emprender e innovar. Reconociendo que
                    el tiempo y el dinero son cruciales en el
                    emprendimiento.</p>
                
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Plataforma de Formación</h3>
                <p>Ofrecemos bloques de trabajo denominados
                    Times, destacando la importancia
                    de la oportunidad y la dedicación en cada
                    etapa formativa. Este enfoque permite a
                    los emprendedores desarrollar y afinar
                    sus ideas de negocio de manera eficiente
                    y estructurada, desde la concepción hasta
                    la implementación.
                </p>
                
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Inteligencia de Negocios</h3>
                <p>Con Campus Emprendedor, invertirás tu
                    tiempo sabiamente, asegurándote de que
                    tu idea sea viable antes de realizar cualquier
                    inversión monetaria. Además, facilitamos
                    entornos para la toma de decisiones
                    informadas y el ajuste de estrategias,
                    apoyándote en cada Time del proceso.</p>
                
            </div>
        </div>
    </div>

</div>
