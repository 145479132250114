<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="assets/img/campus/index/tlaxcala_emprende.jpg" alt="Bootcamp Tlaxcala Emprende 2023">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Convenios Gobiernos</span>
                    <h2>Tlaxcala Emprende</h2>
                    <p>
                        El Gobierno del Estado de Tlaxcala a través de la Secretaría de Desarrollo Económico y Campus Emprendedor ® Power for Thinker.
                        Invitan a emprendedores de todo el Estado para participar en un programa híbrido de emprendimiento bajo el esquema de Bootcamp.
                    </p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Formación empresarial</span></li>
                        <li><span><i class="flaticon-time-left"></i> Semanas 8 </span></li>
                        <li><span><i class="flaticon-tutorials"></i> Cupo limitado</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Minimiza el riesgo </span></li>
                    </ul>
                    <a routerLink="/tlxemprende" class="default-btn"><i class="flaticon-user"></i>Conocer más<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>
