<div class="health-coaching-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-image">
                    <img src="assets/img/campus/index/banner_ce_545.png" alt="LogoEmpresarial">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-content">
                    <h1 class="playfair-display-font">CAMPUS <br>EMPRENDEDOR <br>Power for Thinkers</h1>
                    <p>Plataforma híbrida y dinámica de formación de negocios que abraza y promueve el pensamiento empresarial en todas sus etapas, desde la concepción de ideas hasta la implementación y el crecimiento sostenible.</p>
                    <div class="btn-box">
                        
                        
                        <div class="d-flex align-items-center">
                            <img src="assets/img/campus/index/premio_nacional1.png" class="signature" alt="image"><br>
                            <a routerLink="/acerca_campus" class="default-btn">Nuestro ADN<span></span></a>   
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="health-coaching-shape1"><img src="assets/img/health-coaching-shape1.png" alt="image"></div>
    
    <div class="health-coaching-shape2"><img src="assets/img/health-coaching-shape2.png" alt="image"></div>
    <div class="health-coaching-shape3"><img src="assets/img/health-coaching-shape3.png" alt="image"></div>
    <div class="health-coaching-shape4"><img src="assets/img/health-coaching-shape4.png" alt="image"></div>
    <div class="health-coaching-shape5"><img src="assets/img/health-coaching-shape5.png" alt="image"></div>
    <div class="health-coaching-shape6"><img src="assets/img/health-coaching-shape6.png" alt="image"></div>
    <div class="health-coaching-shape7"><img src="assets/img/health-coaching-shape7.png" alt="image"></div>
    -->
    <div class="divider"></div>
    
</div>