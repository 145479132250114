<div class="page-title-area" style="background-color: #472299;">
    <div class="container">
        <div class="page-title-content">
            <!--<ul>
                <li> <a routerLink="/" style="color: white;"> Home </a></li>
                 <li style="color: white;"> Entornos de negocio</li> 
                <li> <a routerLink="/entorno-autoempleo" style="color: white;"> <strong>  | Fortalece tu Idea de Negocio </strong></a> </li>
            </ul>
            -->
            <h2 class="playfair-display-font" style="color: white;">Entorno de Emprendimiento<br> SOLOPRENEUR</h2>
            <!--
            <div class="rating">
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <div class="rating-count">
                    <span>4.0 (33 rating)</span>
                </div>
            </div>
            -->
        </div>
    </div>
    
</div>

<div class="courses-details-area pb-100">
    
    <div class="courses-details-image" style="text-align: center;">
        <img src="assets/img/campus/entornos/banner_autoempleo.png" alt="image">
    </div>

<div class="container">
    <div class="row">
        <div class="col-lg-8 col-md-12">
            <div class="courses-details-desc">
                <ngx-tabset>

                    <ngx-tab tabTitle="Entornos">
                        <div class="courses-overview">
                            <h3>¿Qué son los entornos de Idea de Negocios?</h3>
                            <p> Son espacios de enseñanza y formación dinámicos diseñados para acompañar
                                 a nuestros Thinkers en cada fase del desarrollo de su idea de negocio. 
                                 Desde la chispa inicial de una idea hasta descubrir su potencial económico y 
                                 sostenibilidad como opción de vida, estos entornos guían a los emprendedores 
                                 en un viaje de transformación.</p>

                            <p>Cada entorno está estructurado para validar, fortalecer y evaluar 
                                sistemáticamente una idea de negocio. En ellos, se analiza meticulosamente 
                                el riesgo y el valor potencial de cada idea, tanto desde la perspectiva del
                                 <strong>Thinker</strong> como en el contexto del mercado real. Utilizamos 
                                 las herramientas y conocimientos del <strong>Campus Emprendedor</strong> 
                                 para cultivar el <strong>pensamiento empresarial </strong>sólido, permitiendo a los 
                                 <strong>Thinkers</strong> tomar decisiones informadas y estratégicas 
                                 sobre su proyecto antes de invertir recursos o realizar ajustes necesarios 
                                 para progresar en sus planes de implementación.</p>

                            <!--<p>Finalmente, los '<strong>Entornos de Emprendimiento</strong>' actúan como 
                                catalizadores donde las ideas se enfrentan a la realidad del mercado. 
                                Los desafíos se convierten en oportunidades y la colaboración estimula a 
                                los <strong>Thinkers</strong> a interactuar con su ecosistema de manera 
                                innovadora minimizando el riesgo de <strong>emprender</strong>. 
                                Estos entornos son cruciales para fomentar una cultura 
                                <strong>emprendedora</strong> vibrante, descubrir nuevos caminos y 
                                forjar un futuro empresarial resiliente y dinámico.</p>
                            -->   
                        </div>
                    </ngx-tab>

                    <ngx-tab tabTitle="SOLOPRENEUR">
                        <div class="courses-overview">
                            <h3> “Fortalecer” </h3>
                            <p>El Entorno <strong>SOLOPRENEUR </strong>está diseñado para Thinkers que buscan convertir su experiencia y conocimientos
                                en negocios exitosos y sostenibles, con base en un
                                producto o servicio en desarrollo con análisis previo. Más allá de
                                generar ingresos, nuestro enfoque se centra en desarrollar una
                                comprensión empresarial integral. Esta etapa se enfrenta a los activos
                                intangibles que le dan madurez visual e impacto en el mercado.</p>

                            <p>Este tipo de <strong>Thinkers</strong> se centra en ofrecer un producto o servicio de alta calidad y mejorar 
                                constantemente sus habilidades técnicas, pero pueden enfrentar desafíos en la adaptación al entorno 
                                empresarial en evolución y en la gestión de aspectos más allá de su experiencia central.</p>

                            <h3>Este entorno está diseñado para:</h3>

                            <ul class="audience-list">
                                <li>
                                    <strong>Autoempleo o Emprendimiento Individual:</strong> Este segmento se dirige a personas que utilizan sus habilidades y experiencia para crear su propio empleo. Se centran en actividades en las que pueden trabajar de manera independiente, como freelancers, artesanos, consultores, etc. Aquí, la necesidad principal es aprender cómo gestionar y promocionar sus servicios de forma efectiva para mantener un flujo de ingresos constante y sostenible.
                                    </li>
                                    <li>
                                    <strong>Thinkers en Etapa de Desarrollo:</strong> Estos son individuos que están en las fases iniciales de convertir su habilidad o idea en un negocio formal. Aunque ya han empezado a actuar sobre su idea, necesitan orientación en aspectos como la formalización del negocio, desarrollo de estrategias de mercado iniciales, estructuras básicas de gestión, y posiblemente búsqueda de financiación inicial.
                                    </li>
                                    <li>
                                    <strong>Creadores de Empresas en Proceso</strong> Este grupo incluye a aquellos que están activamente en el proceso de establecer y expandir su negocio. Pueden tener ya una operación en marcha pero necesitan apoyo en la consolidación y crecimiento del negocio. Este apoyo puede incluir estrategias avanzadas de marketing, gestión de operaciones, escalabilidad del negocio, y acceso a redes y capital para la expansión. 
                                    </li>
                                    
                                    <li>
                                    <strong>Reinventores de carrera:</strong> Personas con ideas novedosas que necesitan ayuda para evaluar si sus conceptos innovadores pueden ser exitosos en el mercado actual. </li>
                                    
                                    
                                    
                                    
                            </ul>

                        </div>
                    </ngx-tab>

                    <ngx-tab tabTitle="Estructura">
                        <div class="courses-curriculum">
                            <h3>Introducción</h3>
                            <ul>
                                
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Fundamento SOLOPRENEUR</span>
                                        <div class="courses-meta">
                                            <span class="duration">1 modulo </span>

                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <h3>SOLOPRENEUR</h3>
                            <ul>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Situación actual</span>
                                        <div class="courses-meta">
                                            
                                            <span class="duration">1 Módulos</span>
                                            
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Alcance de mercado</span>
                                        <div class="courses-meta">
                                            
                                            <span class="duration">4 Módulos</span>
                                            
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Convencer y vender</span>
                                        <div class="courses-meta">
                                            
                                            <span class="duration">3 Módulos</span>
                                            
                                        </div>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Lenguaje online</span>
                                        <div class="courses-meta">
                                            
                                            <span class="duration">3 Módulos</span>
                                            
                                        </div>
                                    </a>
                                </li>
                                
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Activos de desarrollo empresarial</span>
                                        <div class="courses-meta">
                                            
                                            <span class="duration">4 Módulos</span>
                                            
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Modelo de decisiones.</span>
                                        <div class="courses-meta">
                                            <!--<span class="questions"></span>-->
                                            <span class="duration">3 Módulos</span>
                                            <!--<span class="status locked"><i class="flaticon-password"></i></span>-->
                                        </div>
                                    </a>
                                </li>
                                
                               <!--
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Capacidad Productiva</span>
                                        <div class="courses-meta">
                                            <span class="questions">4 Módulos</span>
                                            <span class="duration">5 Horas</span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Marketing Digital</span>
                                        <div class="courses-meta">
                                            <span class="questions">4 Módulos</span>
                                            <span class="duration">5 Horas</span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Capital Humano</span>
                                        <div class="courses-meta">
                                            <span class="questions">3 Módulos</span>
                                            <span class="duration">6 Horas</span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                    </a>
                                </li>
                                -->
                            </ul>
                            

                            <h3>Entregables </h3>
                            <ul>
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Estructura - Product Market fit</span>
                                        <!--
                                        <div class="courses-meta">
                                            <span class="questions"></span>
                                            <span class="duration"></span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                    -->
                                    </a>
                                </li>
                               
                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Riesgo - Capacidad Empresarial</span>
                                        <!--
                                        <div class="courses-meta">
                                            <span class="questions"></span>
                                            <span class="duration"></span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                        -->
                                    </a>
                                </li>

                                <li>
                                    <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Plan de Negocios</span>
                                        <!--
                                        <div class="courses-meta">
                                            <span class="questions"></span>
                                            <span class="duration"></span>
                                            <span class="status locked"><i class="flaticon-password"></i></span>
                                        </div>
                                        -->
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </ngx-tab>

                    <ngx-tab tabTitle="Informes">
                        <div class="courses-overview">

                            <h3>Cómo aplicar</h3>
                               
                            <p> En <strong>Campus Emprendedor</strong>, estamos comprometidos con brindar educación de calidad y 
                                    accesible a todos. Entendemos que cada aspirante tiene circunstancias únicas, por lo que 
                                    ofrecemos diversas opciones de acceso e inscripción para adaptarnos a tus necesidades. </p>
                                    <br>

                            <h4>Aplicación a Becas</h4>
                                <span class="sub-title">(Proyectos de Innovación y Fomento Productivo)</span>
                                <p><strong> Descripción: </strong>Esta opción está dirigida a individuos o equipos que estén trabajando en proyectos de innovación o que contribuyan significativamente al fomento de recursos educativos. La empresa otorga becas para apoyar estas iniciativas, reconociendo y fomentando la creatividad y el emprendimiento.<br>
                                    <strong>Beneficios:</strong> Acceso completo a los cursos desde un 50% a un 100% del costo, asesoría especializada y la posibilidad de integrar los proyectos al ecosistema empresarial.<br>
                                    <strong>Criterios de Elegibilidad:</strong> Presentación de un proyecto innovador o educativo, demostración de impacto potencial, y compromiso con el desarrollo y mejora continua.</p>
                                            <br>

                            <h4>Convenio por Estado </h4>
                                <span class="sub-title">(Acuerdos con Diferentes Estados de la República)</span>
                                <p><strong> Descripción: </strong>Esta modalidad se basa en los acuerdos establecidos con diferentes estados de la República. Está diseñada para beneficiar a residentes de estas regiones, proporcionando acceso a los cursos en condiciones preferenciales.<br>
                                    <strong>Beneficios:</strong> Acceso a cursos con tarifas reducidas o condiciones especiales de financiamiento, dependiendo del acuerdo estatal.<br>
                                    <strong>Criterios de Elegibilidad:</strong> Ser residente del estado con el que existe el convenio y cumplir con los requisitos específicos del acuerdo (que pueden incluir méritos académicos, situación socioeconómica, etc.).</p>
                                            <br>
                            
                             <h4>Financiamiento </h4>
                                <span class="sub-title"></span>
                                <p><strong> Descripción: </strong>Para aquellos que no califican para las becas o los convenios estatales, ofrecemos opciones de financiamiento. Estas opciones están diseñadas para facilitar el acceso a los cursos mediante planes de pago flexibles.<br>
                                    <strong>Beneficios:</strong> Permite a los estudiantes distribuir el costo de los cursos a lo largo del tiempo, haciéndolos más accesibles.<br>
                                    <strong>Criterios de Elegibilidad:</strong> Revisión de la solvencia financiera y compromiso de pago. Puede incluir compromisos futuros dependiendo de los términos del financiamiento.</p>
                                            <br>
                            
                             

                            
                        </div>
                    </ngx-tab>


                    <!--
                    <ngx-tab tabTitle="Registro">
                        <div class="courses-instructor">
                            <div class="single-advisor-box">
                                <div class="row align-items-center">
                                    
                                    <div class="col-lg-4 col-md-4">
                                        <div class="advisor-image">
                                            <img src="assets/img/campus/entornos/banner_e_2.png" alt="image">
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-8 col-md-8">
                                        <div class="advisor-content">
                                            <h3>Aplicación a Becas</h3>
                                            <span class="sub-title">(Proyectos de Innovación y Fomento Productivo)</span>
                                            <p><strong> Descripción: </strong>Esta opción está dirigida a individuos o equipos que estén trabajando en proyectos de innovación o que contribuyan significativamente al fomento de recursos educativos. La empresa otorga becas para apoyar estas iniciativas, reconociendo y fomentando la creatividad y el emprendimiento.<br>
                                                Beneficios: Acceso completo a los cursos sin costo, asesoría especializada y la posibilidad de integrar los proyectos al ecosistema empresarial.<br>
                                                Criterios de Elegibilidad: Presentación de un proyecto innovador o educativo, demostración de impacto potencial, y compromiso con el desarrollo y mejora continua.</p>
                                            <br>
                                        </div>
                                        <div class="advisor-content">
                                            <h3>Simulación de Negocio</h3>
                                            <span class="sub-title">Entregable: Modelo de Negocio</span>
                                            <p>En la plataforma de Campus Emprendedor, crearás simulaciones de negocios que te permitirán explorar escenarios actuales y potenciales. Experimenta con diversas estrategias y modelos de negocio en un entorno seguro antes de aplicarlos en el mundo real, lo que contribuye a reducir riesgos y maximizar el retorno de inversión</p>
                                            <br>
                                        </div>

                                        <div class="advisor-content">
                                            <h3>Inteligencia de Negocios</h3>
                                            <span class="sub-title">Entregable: Plan de Negocio Inteligente</span>
                                            <p>
                                                El enfoque primordial radica en optimizar el proceso de toma de decisiones empresariales. Mediante el empleo de
                                                técnicas de análisis de datos y aprendizaje automático, se accede a información crucial sobre el rendimiento de la idea
                                                de negocio y su contexto. Esto posibilita la identificación de patrones y tendencias, así como la predicción de escenarios
                                                futuros potenciales.
                                            </p>
                                            <ul class="social-link">
                                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </ngx-tab>
                    -->

                    <ngx-tab tabTitle="">

                    </ngx-tab>


                    <!--
                    <ngx-tab tabTitle="Reviews">
                        <div class="courses-reviews">
                            <h3>Course Rating</h3>
                            <div class="rating">
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star"></span>
                            </div>
                            <div class="rating-count">
                                <span>4.1 average based on 4 reviews.</span>
                            </div>
                            <div class="row">
                                <div class="side">
                                    <div>5 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-5"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>02</div>
                                </div>
                                <div class="side">
                                    <div>4 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-4"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>03</div>
                                </div>
                                <div class="side">
                                    <div>3 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-3"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>04</div>
                                </div>
                                <div class="side">
                                    <div>2 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-2"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>05</div>
                                </div>
                                <div class="side">
                                    <div>1 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-1"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>00</div>
                                </div>
                            </div>
                        </div>
                        <div class="courses-review-comments">
                            <h3>3 Reviews</h3>
                            <div class="user-review">
                                <img src="assets/img/user1.jpg" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                    </div>
                                    <span class="d-inline-block">James Anderson</span>
                                </div>
                                <span class="d-block sub-comment">Excellent</span>
                                <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                            </div>
                            <div class="user-review">
                                <img src="assets/img/user2.jpg" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <span class="d-inline-block">Sarah Taylor</span>
                                </div>
                                <span class="d-block sub-comment">Video Quality!</span>
                                <p>Was really easy to implement and they quickly answer my additional questions!</p>
                            </div>
                            <div class="user-review">
                                <img src="assets/img/user3.jpg" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                    </div>
                                    <span class="d-inline-block">David Warner</span>
                                </div>
                                <span class="d-block sub-comment">Perfect Coding!</span>
                                <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                            </div>
                            <div class="user-review">
                                <img src="assets/img/user4.jpg" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <span class="d-inline-block">King Kong</span>
                                </div>
                                <span class="d-block sub-comment">Perfect Video!</span>
                                <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                            </div>
                        </div>
                    </ngx-tab>
                    -->
                </ngx-tabset>
            </div>
        </div>


        <div class="col-lg-4 col-md-12">
            <div class="courses-details-info">
                
                <div class="image">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a data-lg-size="1280-720"
                           data-src="#"
                           data-sub-html="<p>Watch Video</p>"
                           class="d-block">
                            <img src="assets/img/campus/entornos/autoempleo_recuadro.png" alt="image">
                            
                        </a>
                    </lightgallery>
                </div>
                
                <ul class="info">
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-tag"></i>Costo</span>
                            Informes
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-teacher"></i> Modelo</span>
                            Híbrido
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-time"></i> Duración</span>
                            4 Semanas 
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-distance-learning"></i> Contenido</span>
                            5 Times
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-web"></i> Límite</span>
                            Ilimitado
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="flaticon-lock"></i>Inscripción</span>
                            5 febrero 2024 
                        </div>
                    </li>
                </ul>
                <div class="btn-box">
                    <a routerLink="/registro" class="default-btn"><i class="flaticon-tag"></i>Registro<span></span></a>
                </div>
                <!--
                <div class="courses-share">
                    <div class="share-info">
                        <span>Comparte <i class="flaticon-share"></i></span>
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                </div>
                -->
            </div>
        </div>

    </div>
</div>
</div>

<!--
<div class="courses-area bg-f8f9f8 pt-100 pb-70">
<div class="container">
    <div class="section-title">
        <h2>More Courses You Might Like</h2>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$39</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <span>Alex Morgan</span>
                    </div>
                    <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                        <li><i class='flaticon-people'></i> 145 Students</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image">
                        <img src="assets/img/courses/img2.jpg" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$49</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <span>Sarah Taylor</span>
                    </div>
                    <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                        <li><i class='flaticon-people'></i> 100 Students</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image">
                        <img src="assets/img/courses/img3.jpg" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$59</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <span>David Warner</span>
                    </div>
                    <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                        <li><i class='flaticon-people'></i> 150 Students</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
-->