<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">
            <h2 class="playfair-display-font" style="color: white;">Registro ProjectPreneur</h2>
        </div>
    </div>

</div>
<div class="contact-area ptb-100" style="display: flex; justify-content: center; align-items: center; text-align: center; padding: 40px 60px 20px;">
    <p style="font-weight: bold;">El Centro de Innovación, Emprendimiento y Negocios (CIEN) del Estado de Puebla en colaboración con Campus Emprendedor ® Power for Thinker;
        invitan a emprendedores de todo el Estado para participar en un programa híbrido de emprendimiento ProjectPreneur. Este programa está diseñado para ayudar a los
        visionarios que imaginan lo que podría ser, a menudo antes de definir los detalles de ejecución
        de sus ideas de negocio que en Campus Emprendedor llamamos Thinkers y están en proceso de emprender.
    </p>
</div>

<app-how-to-preneur></app-how-to-preneur> <!-- lista para aplicar en campus emprendedor -->

<div class="contact-area ptb-100">
    <div class="container">


        <div class="row">
            <div class="col-lg-6 col-md-12">

                <div class="contact-info">
                    <span class="sub-title"></span>

                    <p>¡Gracias!!!! por tu interés! Nos alegra que formes parte de esta iniciativa
                        para emprendedores. A continuación, completa el formulario para asegurar tu lugar y recibir
                        toda la información necesaria para aprovechar al máximo esta experiencia. Estamos aquí para
                        apoyarte en cada paso de tu camino emprendedor. </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dirección del curso</h3>
                            <p>Calle 5 Norte 134, San Juan Aquiahuac, C.P. 72810, San Andrés Cholula, Puebla. </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contacto</h3>
                            <p>WhatsApp: <a href="WhatsApp:+522220080080">(+52) - 2214 390024</a></p>
                            <p>Mail: <a href="mailto:registro@campusem.com">registro@campusemp.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Horario de atención</h3>
                            <p>Lunes - viernes: 09:00 - 19:00</p>
                            <p>Sábado: 09:00 - 16:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>¡Estás a un paso de ser un Thinker!</h2>
                    <p>Su dirección de correo electrónico no será publicada. Los campos obligatorios están marcados *</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value, $event)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nombre Completo">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">*Nombre completo.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Correo electrónico">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Dirección de correo.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="edad" type="number" #edad="ngModel" class="form-control" id="edad" placeholder="Edad">
                                    <div class="alert alert-danger" *ngIf="edad.touched && !edad.valid">Edad.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                        <select matNativeControl ngModel name="genero" #genero="ngModel" class="form-control" id="genero">
                                          <option value="" selected>Selecciona un sexo</option>
                                          <option value="hombre">Hombre</option>
                                          <option value="mujer">Mujer</option>
                                        </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="¿Por qué me interesa ProjectPreneur?"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">¿Por qué me interesa ProjectPreneur?</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Enviar<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1471718029106!2d-98.30293622574871!3d19.05726585256222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc78308b478ff%3A0x9fe5e4503bbffbe4!2sCentro%20de%20Innovaci%C3%B3n%2C%20Emprendimiento%20y%20Negocios%20(CIEN)!5e0!3m2!1ses-419!2smx!4v1730912069645!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
