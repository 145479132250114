<div class="page-title-area" style="background-color: #111111;">
    <div class="container">
        <div class="page-title-content">
           <!--
            <ul>
                
                <li><a routerLink="/" style="color: white;">Home</a></li>
                <li style="color: white;"> <strong> Alianza Estratégica </strong></li>
                <li style="color: white;"> <strong> Sector Público </strong></li>
            </ul>
            -->
            <h2 style="color: white;">Alianza</h2>
            <h2 style="color: white;">Sector Público</h2>
        </div>
    </div>
   
</div>

<!--
<div class="success-story-area pb-100">
    <div class="container">
        <div class="video-box mt-0">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                    data-sub-html="<p>Watch Video</p>"
                    class="d-block"
                >
                    <div class="image">
                        <img src="assets/img/success-story.jpg" class="shadow" alt="image">
                    </div>
                    <div class="video-btn popup-youtube">
                        <i class="flaticon-play"></i>
                    </div>
                </a>
            </lightgallery>
            <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
    -->
<div class="testimonials-area ptb-100 bg-fef8ef">
    <div class="container">
        <div class="single-testimonials-box">
            <img src="assets/img/campus/acerca/julio_2.png" class="client-img" alt="image">
            <p>
                En Campus Emprendedor, comprendemos los desafíos que el mundo actual presenta en diversas áreas
                de nuestras vidas, desafíos que a menudo están más allá de nuestro control individual. Para muchos de
                nosotros, encontrar el camino para avanzar y crecer en esta coyuntura puede parecer un rompecabezas
                difícil de resolver. Es por eso que buscamos establecer alianzas con gobiernos que tengan el poder
                de potenciar el emprendimiento y contribuir activamente a la creación de políticas públicas que fomenten
                ecosistemas empresariales saludables y competitivos.
            </p>
            <h3>Julio González Jiménez</h3>
            <span>Director Campus Emprendedor</span>
            
        </div>
    </div>
</div>

<app-gob-form></app-gob-form>
<!--
<app-success-people></app-success-people>
-->
