<<div class="page-title-area" style="background-color: #90358d;">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/" style="color: white;">Home</a></li>
                <li><a routerLink="/" style="color: white;">Emprendimiento</a></li>
                <li><a routerLink="/" style="color: white;">Idea de Negocio</a></li>
                <li style="color: white;"><strong> Servicio  </strong></li>
            </ul>
            <h2 style="color: white;">Time - Servicio</h2>
        </div>
    </div>

</div>

<div class="courses-details-area ptb-100">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-title">
                        <p><strong>Bienvenidos al Time - Servicio, durante este programa, te daremos las herramientas y conocimientos necesarios para estructurar tus procesos de negocio de manera eficiente. </strong></p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Categoría</span>
                                <p><strong>Idea de Negocio</strong></p>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Cursos impartidos</span>
                                <p><strong>38</strong></p>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Última actualización</span>
                                <p><strong>03/07/2023</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-price">
                        <div class="courses-review">
                            <!--
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <span class="reviews-total d-inline-block">(3 reviews)</span>
                                 -->
                        </div>
                        <div class="price">$1,800.00 MNX</div>
                        <a routerLink="/" class="default-btn"><i class="flaticon-user"></i>Inscribirse<span></span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-image-style-two text-center">
                    <img src="assets/img/campus/times/time_servicio.png" alt="image">
                </div>
                <div class="courses-details-desc-style-two">
                    <h3>Introducción</h3>

                    <p>
                        Crear experiencias satisfactorias para tus clientes, lo que, a su vez, elevará la calidad de tu entorno y tu competitividad en el sector en el que te desarrolles. A través de una formación sólida en gestión de servicios, aprenderás a identificar las necesidades de tus clientes, diseñar procesos que respondan a esas necesidades y mantener un alto nivel de calidad en la entrega de tus servicios.
                    </p>
                    <p><strong> Beneficios </strong></p>
                    <ul class="description-features-list">
                        <li>
                            <strong>Diseño del Servicio</strong> –

                            Este conocimiento es para ofrecer servicios que se alinean con las necesidades y deseos de sus clientes, mientras se mantiene un equilibrio con las capacidades del mercado.
                        </li>

                        <li>
                            <strong>Nivel de Intervención y Personalización</strong> –

                            Cómo adaptar tus servicios a las necesidades individuales de los clientes y cómo esto impacta la percepción del valor y la satisfacción.
                        </li>

                        <li>
                            <strong>3.	Tiempo Promedio y Eficiencia</strong> –

                            Aprenderán cómo identificar y manejar la complejidad de sus servicios descubriendo estrategias para abordar desafíos y conflictos de manera profesional, manteniendo la satisfacción del cliente.
                        </li>
                    </ul>
                    <h3>Estructura del curso</h3>
                    <div class="courses-curriculum">
                        <!--
                        <h3>Presentación</h3>
                        <ul>
                            <li><a href="#" class="popup-youtube d-flex justify-content-between align-items-center">
                                <span class="courses-name">Proceso de Ideación</span>
                                <div class="courses-meta">
                                    <span class="questions">5 questions</span>
                                    <span class="duration">01 Hour</span>
                                    <span class="status">Preview</span>
                                </div>
                            </a></li>
                        </ul>
                        -->
                        <h3>Time - El Servicio</h3>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Tipo de servicio</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Procesos de negocio</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Experiencia del usuario</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Costo/Beneficio</span>
                                    <div class="courses-meta">

                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Ejemplos de servicio</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <h3>Que aprenderás en Time - La Idea</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Forma del servicio
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Características de los servicios
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Intervención, cercanía con el cliente
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Elementos clave del servicio
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Recurso humano
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Determinar el costo/precio del servicio
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Nivel de especialización 
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Nivel de satisfacción del cliente 
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Estategias en toma de deciciones
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Simulación de negocios
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!--
                    <h3>Requirements</h3>
                    <ul class="requirements-list">
                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</li>
                        <li>The standard Lorem Ipsum passage, used since the 1500s.</li>
                    </ul>
                        -->
                    <h3>Quien puede aplicar a este curso</h3>
                    <ul class="audience-list">
                        <li>Emprendedores Novatos: Personas que están interesadas en emprender pero pueden no tener experiencia previa en el mundo empresarial.</li>
                        <li>Emprendedores Experimentados: Aquellos que ya han iniciado negocios previamente y están buscando nuevas oportunidades.</li>
                        <li>Estudiantes: Estudiantes universitarios o de posgrado que desean explorar ideas empresariales y proyectos como parte de su formación académica.</li>
                        <li>Profesionales en Transición: Personas que están buscando un cambio de carrera o que han perdido sus empleos y consideran la opción de iniciar un negocio.</li>
                    </ul>





                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-sidebar-information">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Formación</span>
                                Pensamiento empresarial
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duración</span>
                                5 Horas
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lecciones</span>
                                5
                            </div>
                        </li>
                        <!--
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                            -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-html"></i> Idioma</span>
                                Español
                            </div>
                        </li>
                        <!--
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-caption"></i> Video Subtitle</span>
                                English
                            </div>
                        </li>
                            -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Cierre</span>
                                31 Diciembre
                            </div>
                        </li>

                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-certification"></i> Acreditación</span>
                                Si
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<!--
<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Courses You Might Like</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    -->
