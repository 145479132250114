<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/campus/index/CE_footer.png" alt="logo"></a>
                    <p>En Campus Emprendedor, encendemos la chispa del pensamiento empresarial, equipando a nuestros Thinkers 
                        con el conocimiento esencial para lanzarse al mundo del emprendimiento con seguridad y el menor 
                        riesgo posible.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/CampusEmprendedorIDX" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/CampusIdx" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explora</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/acerca_campus">Acerca de CE</a></li>
                        <li><a routerLink="/forma-contacto">Alianzas Estratégicas</a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Entornos</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/entorno-idea-de-negocio">IDEAPRENEUR</a></li>
                        <li><a routerLink="/entorno-autoempleo">SOLOPRENEURS</a></li>
                        <li><a routerLink="/entorno-inversion">INVERPRENEURS</a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dirección</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Prolongación de la C. Victoria #10, San Miguel Apetlachica, 72713 Sanctorum, Pue</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+522214390024">+52 2214-390-024</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:informes@campusemp.com">informes@campusemp.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+522214390024">+52 2214-390-024</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Campus Emprendedor desarrollado por  <a href="https://ideasdx.com/" target="_blank">Ideas DX</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/aviso-privacidad">Aviso de Privacidad |   </a></li>
                        <li><a routerLink="/terminos-de-servicio">Terminos de Servicio</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
