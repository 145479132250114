<div class="page-title-area" style="background-color: #f58020;">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <
                <li><a routerLink="/" style="color: white;">Home</a></li>
                <li style="color: white;"> <strong> Alianza Estratégica </strong></li>
                <li style="color: white;"> <strong> ONG </strong></li>
            </ul>
            <h2 style="color: white;">Alianza ONG</h2>
        </div>
    </div>

</div>

<!--
<div class="success-story-area pb-100">
    <div class="container">
        <div class="video-box mt-0">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                    data-sub-html="<p>Watch Video</p>"
                    class="d-block"
                >
                    <div class="image">
                        <img src="assets/img/success-story.jpg" class="shadow" alt="image">
                    </div>
                    <div class="video-btn popup-youtube">
                        <i class="flaticon-play"></i>
                    </div>
                </a>
            </lightgallery>
            <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
    -->
<div class="testimonials-area ptb-100 bg-fef8ef">
    <div class="container">
        <div class="single-testimonials-box">
            <img src="assets/img/campus/acerca/julio_2.png" class="client-img" alt="image">
            <p>
                Somos plenamente conscientes de que existen individuos con talentos excepcionales que merecen ser cultivados
                y empoderados. Establecemos colaboraciones con organizaciones tanto nacionales como internacionales,
                reconociendo sus necesidades particulares; estamos comprometidos en trabajar
                de la mano con estas organizaciones para generar un impacto significativo en la sociedad. Creemos que la
                colaboración conjunta traza nuevos caminos y abre puertas a oportunidades de negocio innovadoras,
                beneficiando a todos los involucrados en este viaje de desarrollo y crecimiento.
            </p>
            <h3>Julio González Jiménez</h3>
            <span>Director General Campus Emprendedor</span>
            
        </div>
    </div>
</div>

<app-ong-form></app-ong-form>
<!--
<app-success-people></app-success-people>
-->
