<div class="coaching-types-area pt-100 pb-70">
   
    <div class="container">
        <div class="section-title">
            <span class="sub-title"> Alianzas estratégicas</span>
            <h2>Expandiendo Horizontes Empresariales</h2>
            <p>
                Las alianzas estratégicas nos permiten ofrecer a nuestra comunidad un acceso exclusivo a conocimientos,
                recursos y oportunidades globales para impulsar sus proyectos empresariales
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/campus/index/ONG.png" alt="image">
                    <h3 class="inter-font">Alianza con ONG</h3>
                    <p>Se basa en un compromiso compartido de empoderar a las comunidades,
                    fomentar el espíritu emprendedor y crear un impacto social duradero </p>
                    <a routerLink="/alianza-ong" class="link-btn">Conocer más</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/campus/index/gobierno.png" alt="image">
                    <h3 class="inter-font">Alianza con Sector Público</h3>
                    <p>Busca el compromiso compartido de estimular la
                    actividad empresarial y promover el desarrollo económico sostenible con políticas eficientes.</p>
                    <a routerLink="/alianza-gobierno" class="link-btn">Conocer más</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-coaching-types-box">
                    <img src="assets/img/campus/index/empresas.png" alt="image">
                    <h3 class="inter-font">Alianza con Iniciativa Privada</h3>
                    <p>Tiene la visión compartida de fomentar la innovación y el
                    crecimiento empresarial, crear empleos y promover la formación
                    del pensamiento empresarial</p>
                    <a routerLink="/alianza-ip" class="link-btn">Conocer más</a>
                </div>
            </div>
        </div>
    </div>
</div>
