<div class="page-title-area" style="background-color: #f58020;">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <
                <li><a routerLink="/" style="color: white;">Home</a></li>
                <li style="color: white;"> <strong> Times </strong></li>
            </ul>
            <h2 style="color: white;">Times</h2>
        </div>
    </div>
   
</div>

<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>Encontramos <span class="count">10</span> Cursos liberados</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Ideas de negocio</option>
                       
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-idea" class="d-block image">
                            <img src="assets/img/campus/times/time_idea.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-idea">La idea - Valida tu idea de negocio</a></h3>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-capital-humano" class="d-block image">
                            <img src="assets/img/campus/times/time_capital_humano.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-capital-humano">Activos de desarrollo empresarial - Capital Humano</a></h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-marketing" class="d-block image">
                            <img src="assets/img/campus/times/time_marketing.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-marketing">Marketing - Convencer y vender</a></h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-mktdigital" class="d-block image">
                            <img src="assets/img/campus/times/time_marketing_digital.png" alt="image">
                        </a>
                        <a routerLink="/time-mktdigital" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-mktdigital">Marketing Digital - Lenguaje On line </a></h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-mercado" class="d-block image">
                            <img src="assets/img/campus/times/time_mercado_objetivo.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-mercado">Mercado objetivo - Alcance de marcado</a></h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-producto" class="d-block image">
                            <img src="assets/img/campus/times/time_producto.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-producto">Producto - De la idea a la realidad</a></h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-version-salida" class="d-block image">
                            <img src="assets/img/campus/times/time_mvp.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-version-salida">Versión de salida - MVP o Prototipo</a></h3>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/time-servicio" class="d-block image">
                            <img src="assets/img/campus/times/time_servicio.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/time-servicio">Servicio - De la idea a la realidad</a></h3>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/" class="d-block image">
                            <img src="assets/img/campus/times/time_integra.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/">Integra - Inteligencia de negocios</a></h3>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a routerLink="/" class="d-block image">
                            <img src="assets/img/campus/times/time_implementa.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/">Implementa - Habemus negocio</a></h3>
                        
                    </div>
                </div>
            </div>
            
           
        </div>
    </div>
</div>
