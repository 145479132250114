<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home CE</a></li>
                <li><a routerLink="/">Convenios</a></li>
                <li>Tlaxcala Emprende</li>
            </ul>
            <h2>Bootcamp: Tlaxcala Emprende</h2>
            <!--
            <div class="rating">
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <i class='bx bxs-star'></i>
                <div class="rating-count">
                    <span>4.0 (33 rating)</span>
                </div>
            </div>
            -->
        </div>
    </div>
    
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/campus/tlxemprende/portada_tlaxcala_emprende.png" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Convenio">
                            <div class="courses-overview">

                                <p>La Secretaría de Desarrollo Económico del Estado de Tlaxcala en colaboración Campus Emprendedor ® Power for Thinker; invitan a emprendedores de todo el Estado para participar en un programa híbrido de emprendimiento bajo el esquema de Bootcamp</p>
                                <p>Este programa está diseñado para ayudar a los emprendedores en proceso de emprender en el estado de Tlaxcala a validar sus ideas de negocio y obtener una experiencia de aprendizaje inmersiva</p>
                                <h3>Selección</h3>
                                <p>
                                    Se seleccionarán 25 emprendedores de todo el estado, quienes durante 7 semanas participarán de un programa formativo de 52 horas en emprendimiento e innovación de la mano de la metodología creada por los ganadores del premio nacional del emprendedor “Power for Thinker”.
                                </p>
                                <h3>?Quién puede participar¿</h3>
                                <p>
                                    Los candidatos deben cumplir entre otro con los siguientes requisitos<br><br>

                                    Tener entre 21 y 65 años<br>
                                    Vivir en el estado de Tlaxcala<br>
                                    Tener un proyecto con al menos una idea de negocio en potencia<br>
                                    En el caso de tener un producto mínimo viable, poder ser acompañado<br>
                                    Disponibilidad de tiempo<br>
                                    Pagar la inscripción de $3,000.00 MXN<br>
                                    En caso de tener un proyecto para el estado de Tlaxcala puede postular, teniendo la disposición para tomar el curso de forma presencial en la ubicación que se indique.
                                </p>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Temario">
                            <div class="courses-curriculum">
                                <h3>?Qué soluciona Bootcamp Tlaxcala Emprende¿</h3>
                                <ul>
                                    <li>
                                        <a href="#" class="popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Un paseo por Campus Emprendedor</span>
                                            <div class="courses-meta">
                                                <span class="duration">30 Minutos</span>
                                                <span class="status">Introducción</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <h3>Bootcamp Tlaxcala Emprende</h3>
                                <ul>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Ideación</span>
                                            <div class="courses-meta">
                                                <span class="questions"> 4 Módulos</span>
                                                <span class="duration">Horas 4</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Segmentación de Mercado</span>
                                            <div class="courses-meta">
                                                <span class="questions"> 4 Módulos</span>
                                                <span class="duration">Horas 6</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Producto</span>
                                            <div class="courses-meta">
                                                <span class="questions"> 4 Módulos</span>
                                                <span class="duration">6 horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Servicio</span>
                                            <div class="courses-meta">
                                                <span class="questions">4 Módulos</span>
                                                <span class="duration">6 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Producto Mínimo Viable</span>
                                            <div class="courses-meta">
                                                <span class="questions">3 Módulos</span>
                                                <span class="duration">4 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Marketing</span>
                                            <div class="courses-meta">
                                                <span class="questions">4 Módulos</span>
                                                <span class="duration">5 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Marketing Digital</span>
                                            <div class="courses-meta">
                                                <span class="questions">4 Módulos</span>
                                                <span class="duration">5 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Capital Humano</span>
                                            <div class="courses-meta">
                                                <span class="questions">3 Módulos</span>
                                                <span class="duration">6 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Integración</span>
                                            <div class="courses-meta">
                                                <span class="questions">4 Módulos</span>
                                                <span class="duration">5 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                            <span class="courses-name">Implementación</span>
                                            <div class="courses-meta">
                                                <span class="questions">3 Módulos</span>
                                                <span class="duration">6 Horas</span>
                                                <span class="status locked"><i class="flaticon-password"></i></span>
                                            </div>
                                        </a>
                                    </li>

                                </ul>



                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Herramientas">
                            <div class="courses-instructor">
                                <div class="single-advisor-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="advisor-image">
                                                <img src="assets/img/campus/tlxemprende/banner_ce_2.png" alt="image">
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8">
                                            <div class="advisor-content">
                                                <h3>Formación Híbrida</h3>
                                                <span class="sub-title">Tu camino al conocimiento, en todas las formas</span>
                                                <p>Experimenta una formación híbrida única: únete a los creadores de Campus Emprendedor en sesiones presenciales del bootcamp, y accede a la plataforma para revisar términos y ponencias a través de videos de formación</p>
                                                <br>
                                            </div>
                                            <div class="advisor-content">
                                                <h3>Simulación de Negocio</h3>
                                                <span class="sub-title">Identificación de los puntos críticos del negocio</span>
                                                <p>En la plataforma de Campus Emprendedor, crearás simulaciones de negocios que te permitirán explorar escenarios actuales y potenciales. Experimenta con diversas estrategias y modelos de negocio en un entorno seguro antes de aplicarlos en el mundo real, lo que contribuye a reducir riesgos y maximizar el retorno de inversión</p>
                                                <br>
                                            </div>

                                            <div class="advisor-content">
                                                <h3>Inteligencia de Negocios</h3>
                                                <span class="sub-title">Evaluación constante de los resultados del negocio</span>
                                                <p>
                                                    El enfoque primordial radica en optimizar el proceso de toma de decisiones empresariales. Mediante el empleo de
                                                    técnicas de análisis de datos y aprendizaje automático, se accede a información crucial sobre el rendimiento de la idea
                                                    de negocio y su contexto. Esto posibilita la identificación de patrones y tendencias, así como la predicción de escenarios
                                                    futuros potenciales.
                                                </p>
                                                <ul class="social-link">
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <!--
                        <ngx-tab tabTitle="Reviews">
                            <div class="courses-reviews">
                                <h3>Course Rating</h3>
                                <div class="rating">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star"></span>
                                </div>
                                <div class="rating-count">
                                    <span>4.1 average based on 4 reviews.</span>
                                </div>
                                <div class="row">
                                    <div class="side">
                                        <div>5 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-5"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>02</div>
                                    </div>
                                    <div class="side">
                                        <div>4 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-4"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>03</div>
                                    </div>
                                    <div class="side">
                                        <div>3 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-3"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>04</div>
                                    </div>
                                    <div class="side">
                                        <div>2 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-2"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>05</div>
                                    </div>
                                    <div class="side">
                                        <div>1 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-1"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>00</div>
                                    </div>
                                </div>
                            </div>
                            <div class="courses-review-comments">
                                <h3>3 Reviews</h3>
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">James Anderson</span>
                                    </div>
                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                        </ngx-tab>
                        -->
                    </ngx-tabset>
                </div>
            </div>

            
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    
                    <div class="image">
                        <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                            <a data-lg-size="1280-720"
                               data-src="#"
                               data-sub-html="<p>Watch Video</p>"
                               class="d-block">
                                <img src="assets/img/campus/tlxemprende/tlxvideo.png" alt="image">
                                <div class="content">
                                    
                                    
                                </div>
                            </a>
                        </lightgallery>
                    </div>
                
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Costo</span>
                                $6,000.00-MNX
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Modelo</span>
                                Híbrido
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duración</span>
                                semanas 8
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Times</span>
                                Módulos 9
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Límite</span>
                                +25 participantes
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Acceso</span>
                                Septiembre 23
                             </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeBMTm9TYr-XZdSiYoD_1FLBprvVLj_mgZ0ZirvREFWKKWCow/viewform?usp=pp_url" class="default-btn"><i class="flaticon-tag"></i>Regsitro<span></span></a>
                    </div>
                    <div class="courses-share">
                        <div class="share-info">
                            <span>Comparte <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--
<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Courses You Might Like</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
