<app-health-coaching-banner></app-health-coaching-banner>

<app-lifestyle></app-lifestyle> <!-- entornos de emprendimientos -->

<app-health-feedback></app-health-feedback>

<!-- <app-program></app-program> alianzas estrategigcas-->

<div class="bg-fffaf3"> <!-- contador de emprendimientos -->
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<app-coaching-program></app-coaching-program> <!-- próximos eventos -->

<div class="partner-area bg-fe4a55 ptb-70">
    <div class="container">
        <app-partner></app-partner> <!-- logotipos -->
    </div>
</div>


<!--
<app-services></app-services>

<app-health-experience></app-health-experience>

<app-health-courses></app-health-courses>

<app-health-feedback></app-health-feedback>

<app-health-events></app-health-events>

<app-health-blog></app-health-blog>

<app-subscribe-style-three></app-subscribe-style-three>
-->