<div class="our-story-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number" style= "color: #f58020;"></span> Que ofrecemos </h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>
                        Campus Emprendedor se dedica a facilitar herramientas y oportunidades para ONG nacionales e
                        internacionales, estableciendo un entorno propicio para el fomento del emprendimiento. Nuestra misión
                        es impulsar acciones que generen un impacto tangible en la sociedad, ya sea de manera directa o
                        indirecta. Esto se logra mediante la promoción de cadenas productivas, la creación de hojas de ruta
                        para proyectos emprendedores y una evaluación constante de iniciativas.

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number" style= "color: #f58020;">1</span> Proyectos Sociales y Cadenas Productivas</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>
                        El desarrollo de proyectos sociales con un enfoque en el emprendimiento y las cadenas productivas se centra en impulsar iniciativas que generen un impacto
                        positivo en las comunidades al promover el espíritu emprendedor y fortalecer las estructuras empresariales locales.
                        Este enfoque tiene como objetivo cerrar las brechas en la formación empresarial y crear oportunidades de negocio sostenibles.

                    </p>
                </div>
            </div>
            <!--
            <div class="col-lg-12 col-md-12">
                <div class="our-story-image">
                    <img src="assets/img/story-img.jpg" alt="image">
                </div>
            </div>
            -->

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number" style= "color: #f58020;">2</span> Laboratorio Social de Emprendimiento</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Campus Emprendedor opera como un laboratorio social de emprendimiento, donde se facilita la
                        colaboración entre diversas partes interesadas para aprovechar la información a través de datos
                        abiertos, big data e inteligencia artificial. Nuestra misión es brindar herramientas que promuevan
                        la interconexión efectiva en el entorno empresarial. Siempre estamos enfocados en abordar los
                        riesgos clave asociados con el emprendimiento social a mediano y largo plazo

                    </p>

                   
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number" style= "color: #f58020;">3</span> Monitores de Entorno Emprendedor</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">

                    <p>
                        Implica proporcionar un apoyo crucial a los emprendedores al ayudarlos a identificar y mitigar
                        riesgos. Esto se logra a través del uso de indicadores y marcadores específicos adaptados a cada
                        tipo de emprendimiento. Nuestro objetivo es asegurarnos de que los recursos se utilicen de manera
                        eficiente y estratégica, enfocándose en acciones que permitan el desarrollo y el crecimiento antes
                        de considerar inversiones significativas.

                    </p>


                   
                </div>
            </div>
        </div>
    </div>
</div>
