import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-times-inicial',
  templateUrl: './times-inicial.component.html',
  styleUrls: ['./times-inicial.component.scss']
})
export class BloqueTimes implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
