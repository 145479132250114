import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-bloque-pmv',
  templateUrl: './courses-bloque-pmv.component.html',
    styleUrls: ['./courses-bloque-pmv.component.scss']
})
export class CourseBloqueVersion implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
