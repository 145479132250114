<div class="main-banner-wrapper">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-wrapper-content">
                    <h1>CAMPUS</h1>
                    <h1>EMPRENDEDOR</h1>

                    <p style="color: #f58020;"> <strong>POWER FOR THINKER </strong></p>
                    <br>
                    <br>
                    <br>
                    <br>
                    <h2>Plataforma de Formación Empresarial</h2>


                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-wrapper-image text-center">
                    <img src="assets/img/campus/index/campus_emprendedor.png" alt="Logotipo Campus Emprendedor">
                </div>
            </div>
        </div>
    </div>
    
    <div class="banner-shape14"><img src="assets/img/campus/index/ce_icono.png" alt="image"></div>
    <div class="banner-shape15"><img src="assets/img/campus/index/premio_emprendedor.png" alt="image"></div>
    
    
</div>
