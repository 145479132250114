<<<div class="page-title-area" style="background-color: #078dc9;">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/" style="color: white;">Home</a></li>
                <li><a routerLink="/" style="color: white;">Emprendimiento</a></li>
                <li><a routerLink="/" style="color: white;">Idea de Negocio</a></li>
                <li style="color: white;"><strong> La Idea  </strong></li>
            </ul>
            <h2 style="color: white;">Time - La Idea</h2>
        </div>
    </div>

</div>

<div class="courses-details-area ptb-100">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-title">
                        <p><strong>Bienvenidos al Time La Idea, durante este programa, exploraremos cómo generar ideas de negocio sólidas mediante un enfoque estructurado y estratégico </strong></p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Categoría</span>
                                <p><strong>Idea de Negocio</strong></p>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Cursos impartidos</span>
                                <p><strong>38</strong></p>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Última actualización</span>
                                <p><strong>03/07/2023</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-price">
                        <div class="courses-review">
                            <!--
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                               
                            <span class="reviews-total d-inline-block">(3 reviews)</span>
                                 -->
                        </div>
                        <div class="price"> $1,000.00 - MXN </div>
                        <a routerLink="/" class="default-btn"><i class="flaticon-user"></i>Inscribirse<span></span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-image-style-two text-center">
                    <img src="assets/img/campus/times/portada_ideacion_ce.png" alt="image">
                </div>
                <div class="courses-details-desc-style-two">
                    <h3>Introducción</h3>

                    <p>
                        Nuestro objetivo principal es que puedan visualizar más allá de la idea inicial, integrando elementos clave que los guiarán hacia un panorama general en el cual su idea podrá florecer, evitando decisiones que puedan conducir al fracaso o a daños mayores en sus procesos de creación.
                        <br><br>Sentaremos las bases para desarrollar ideas innovadoras y eficientes. Comenzaremos definiendo claramente su idea de negocio. Esto implica comprender y articular de manera precisa el propósito y la propuesta de valor de su idea. Al definir su idea de negocio, podrán establecer una base sólida que les permitirá avanzar en el proceso de ideación con claridad y enfoque.
                    </p>
                    <p><strong> Beneficios </strong></p>
                    <ul class="description-features-list">
                        <li><strong>Dirección Clara y Marco de Referencia</strong> – Proporcionar una dirección clara y un marco de referencia sólido para desarrollar su idea de negocio. Al contar con una definición precisa, los participantes podrán visualizar los pasos necesarios para convertir su concepto en realidad. Esta estructura evita la dispersión y les permite concentrar sus esfuerzos en áreas cruciales para el éxito empresarial</li>
                        <li><strong>De la Intención a la Acción</strong> – es fundamental recordar que la mera intención no basta; la implementación es esencial para transformar una idea en una realidad palpable. Durante el taller, se les proporcionarán herramientas y técnicas que les permitirán trascender la barrera de la mera intención y abrazar la acción. El enfoque estará en diseñar un plan de ejecución concreto para llevar sus ideas de negocio al siguiente nivel</li>
                        <li><strong>Destacar en un Mercado Competitivo</strong> – Abordar el hecho de que "tu idea no es única" es esencial en el proceso de ideación. Esta perspectiva, aunque parezca contradictoria, en realidad representa una oportunidad. La existencia de ideas similares en el mercado demuestra la demanda de ese tipo de solución. En este bloque, los participantes aprenderán a diferenciarse y agregar valor a su idea de negocio. Se les enseñará a identificar su propuesta única y a destacar en un entorno competitivo</li>
                    </ul>
                    <h3>Estructura del curso</h3>
                    <div class="courses-curriculum">
                        <!--
                        <h3>Presentación</h3>
                        <ul>
                            <li><a href="#" class="popup-youtube d-flex justify-content-between align-items-center">
                                <span class="courses-name">Proceso de Ideación</span>
                                <div class="courses-meta">
                                    <span class="questions">5 questions</span>
                                    <span class="duration">01 Hour</span>
                                    <span class="status">Preview</span>
                                </div>
                            </a></li>
                        </ul>
                        -->
                        <h3>Time - La Idea</h3>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Proceso de ideación</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Enfoque y solución</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">El ADN empresarial</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Tu cliente</span>
                                    <div class="courses-meta">

                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="locked popup-youtube d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Ejemplos</span>
                                    <div class="courses-meta">
                                        <span class="duration">1 Hora</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <h3>Que aprenderás en Time - La Idea</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Saber si es alcanzable tu idea
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    El nivel de riesgo que tendrás que aofrontar
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Lo necesita el mercado
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Elige tu equipo de trabajo
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Cómo diferenciar oportunidades
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Establcer tus fortalezas primarias
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Saber quién es tu cliente
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Analizar tu entorno
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Implementar tu idea de negocio
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='flaticon-tick'></i>
                                    Decidir seguir adelante o no
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!--
                    <h3>Requirements</h3>
                    <ul class="requirements-list">
                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</li>
                        <li>The standard Lorem Ipsum passage, used since the 1500s.</li>
                    </ul>
                        -->
                    <h3>Quien puede aplicar a este curso</h3>
                    <ul class="audience-list">
                        <li>Emprendedores Novatos: Personas que están interesadas en emprender pero pueden no tener experiencia previa en el mundo empresarial.</li>
                        <li>Emprendedores Experimentados: Aquellos que ya han iniciado negocios previamente y están buscando nuevas oportunidades.</li>
                        <li>Estudiantes: Estudiantes universitarios o de posgrado que desean explorar ideas empresariales y proyectos como parte de su formación académica.</li>
                        <li>Profesionales en Transición: Personas que están buscando un cambio de carrera o que han perdido sus empleos y consideran la opción de iniciar un negocio.</li>
                    </ul>





                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-sidebar-information">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Quien puede Aplicar</span>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tick"></i>Emprendedores</span>
                                Novatos/Expertos
                            </div>
                        </li>

                         <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tick"></i>Estudiantes</span>
                                Mayores de edad
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tick"></i>Profesionales</span>
                                En Transición
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Información General</span>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duración</span>
                                6 Horas
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> Lecciones</span>
                                5
                            </div>
                        </li>
                        <!--
    <li>
        <div class="d-flex justify-content-between align-items-center">
            <span><i class="flaticon-web"></i> Enrolled</span>
            255 students
        </div>
    </li>
        -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-html"></i> Idioma</span>
                                Español
                            </div>
                        </li>
                        <!--
    <li>
        <div class="d-flex justify-content-between align-items-center">
            <span><i class="flaticon-caption"></i> Video Subtitle</span>
            English
        </div>
    </li>
        -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Cierre</span>
                                31 Diciembre
                            </div>
                        </li>

                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-certification"></i> Acreditación</span>
                                Si
                            </div>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!--
<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Courses You Might Like</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    -->
