<div class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Taller del Mes</span>
            <h2>El Know How del Product Market Fit</h2>
            <p>A través de tres bloques estratégicos, te guiaremos en la evaluación de tu Producto Market Fit. Esta evaluación es crucial para determinar la viabilidad de tu producto o servicio en el mercado. Al alcanzar un ajuste adecuado, minimizamos tu riesgo, atraerás y retendrás clientes, y establecerás una base sólida para el crecimiento a largo plazo.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/indexce" class="d-block image">
                            <img src="assets/img/campus/index/portada_producto_ce.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">On</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>
                                Manos a la obra, procesos de negocio
                            </span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Producto o Servicio</a></h3>
                        <p>Un producto o servicio bien ajustado al mercado atrae a los clientes adecuados y los mantiene satisfechos, lo que conduce a la lealtad y la repetición de compras</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 4 Módulos</li>
                            <li><i class='flaticon-people'></i> 5 Horas</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/campus/index/portada_mercado_ce.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">On</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>De la Oferta a la Demanda</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Alcance de Mercado</a></h3>
                        <p>Al satisfacer las necesidades del mercado, el producto puede crecer y expandirse de manera sostenible, atrayendo a nuevos clientes y manteniendo los existentes</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 4 Módulos</li>
                            <li><i class='flaticon-people'></i> 7 Horas</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/campus/index/portada_ideacion_ce.png" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow"> On</div>
                    </div>
                    
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Valida tu Idea de Negocio</a></h3>
                        <p>Confirma que el producto o servicio tiene un mercado dispuesto a comprarlo, lo que reduce el riesgo de fracaso y aumenta las posibilidades de éxito a largo plazo</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 4 Módulos</li>
                            <li><i class='flaticon-people'></i> 5 Horas</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Únete a nuestro curso de Product Market Fit y aprende a validar tu idea para un crecimiento sostenible. ¡Inscríbete hoy mismo y comienza a construir un negocio sólido! <a routerLink="/profile-authentication">inscribete ahora</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
