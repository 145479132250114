<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses List 01</li>
            </ul>
            <h2>Courses List 01</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">12</span> courses available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img1.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$39</span>
                                <h3><a routerLink="/single-courses-1">Agile Crash Course: Agile Project Management</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img2.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$99</span>
                                <h3><a routerLink="/single-courses-1">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img3.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$49</span>
                                <h3><a routerLink="/single-courses-1">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                    <li><i class='flaticon-people'></i> 104 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img4.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$79</span>
                                <h3><a routerLink="/single-courses-1">Mathematical Foundation For Machine Learning and AI</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img5.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$59</span>
                                <h3><a routerLink="/single-courses-1">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img6.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$89</span>
                                <h3><a routerLink="/single-courses-1">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 200 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img7.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$39</span>
                                <h3><a routerLink="/single-courses-1">Data Visualization with Python and Matplotlib</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img8.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$99</span>
                                <h3><a routerLink="/single-courses-1">Static and Interactive Data Visualizations in R</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img9.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$49</span>
                                <h3><a routerLink="/single-courses-1">R for Data Science: Learn R Programming in 2 Hours</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                    <li><i class='flaticon-people'></i> 104 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img10.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$79</span>
                                <h3><a routerLink="/single-courses-1">Complete Python Developer in 2020: Zero to Mastery</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img11.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$59</span>
                                <h3><a routerLink="/single-courses-1">Python for Beginners with Examples</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img12.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$89</span>
                                <h3><a routerLink="/single-courses-1">Python Django Web Development: To-Do App</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 200 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/courses-5" class="prev page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-5" class="page-numbers">2</a>
                    <a routerLink="/courses-5" class="page-numbers">3</a>
                    <a routerLink="/courses-5" class="page-numbers">4</a>
                    <a routerLink="/courses-5" class="next page-numbers"><i class='bx bx-chevrons-left'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>