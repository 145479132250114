<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">
            <h2 class="playfair-display-font" style="color: white;">Aviso de Privacidad Campus Emprendedor</h2>
        </div>
    </div>
   
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="privacy-policy-content">
                    <img src="assets/img/campus/privacidad01.png" alt="Aviso de Privacidad">
                    <p><i>Esta Política de Privacidad fue actualizada por última vez el 1 de enero de 2024</i></p>
                    
                    <h3>1. Identidad y Domicilio del Responsable</h3>
                    <blockquote class="blockquote">
                        <p>Campus Emprendedor Idx, con domicilio en Prolongación de la C. Victoria #10, San Miguel Apetlachica, 72713 Sanctorum, Pue es el responsable del tratamiento de los datos personales que nos proporcioné. Nuestros medios de contacto son: teléfono +52 2214-390-024 y correo electrónico direccion@campusemp.com.</p>
                    </blockquote>

                    <h3>2. Datos Personales Recabados y el Fin de su Tratamiento</h3>
                    <blockquote class="blockquote">
                        <p>Recabamos sus datos personales de forma directa cuando usted mismo nos los proporciona por diversos medios, como pueden ser aplicaciones electrónicas, formularios web, y eventos. Los datos que solicitamos incluyen, pero no se limitan a: nombre completo, dirección de correo electrónico, número telefónico y dirección postal.</p>
                    </blockquote>

                    
                    <h3>3. Como usaremos la información</h3>
                    <p>Los datos personales que recopilamos de usted serán utilizados para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                    <h3>4. Para que usaremos la información </h3>
                    <ol>
                        <li>Registro y participación en eventos y programas de emprendimiento;</li>
                        <li>Envío de información relacionada con convocatorias, cursos, y oportunidades de financiamiento;</li>
                        <li>Colaboración con organismos nacionales e internacionales con los cuales tenemos convenios</li>
                        <li>Enviando notificaciones push a tu dispositivo inalámbrico para proporcionar actualizaciones y otros mensajes relevantes (los cuales puedes gestionar desde la página de “la plataforma de trabajo” o “configuración” de la aplicación móvil);</li>
                    </ol>

                    <h3>5. Transferencias de Datos Previstas</h3>
                    <p>Informamos que sus datos personales pueden ser compartidos dentro y fuera del país con los siguientes organismos y entidades con los cuales [Nombre del Campus Emprendedor] tiene convenios:</p>
                    <ul>
                        <li>Organismos estatales y federales para la promoción del emprendimiento.
                        <li>Instituciones internacionales de apoyo a emprendedores.</li>
                        
                    </ul>
                    <p>El fin de estas transferencias es exclusivamente para cumplir con los programas y convenios establecidos, asegurando en todo momento la protección de sus datos personales.   </p>
                   
                    <h3>6. Derechos ARCO</h3>
                    <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos (Acceso), a solicitar la corrección de sus datos personales en caso de que estén desactualizados, sean inexactos o incompletos (Rectificación), a que los eliminemos de nuestros registros o bases de datos cuando considere que no están siendo utilizados adecuadamente (Cancelación), así como a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
                
                    <h3>7. Mecanismos para Ejercer Derechos ARCO</h3>
                    <p>Para ejercer sus derechos ARCO, usted deberá enviar una solicitud al correo electrónico direccion@campusemp.com, adjuntando una copia de su identificación oficial y detallando los datos o tratamientos sobre los cuales busca ejercer alguno de sus derechos.</p>
                
                    <h3>8. Consentimiento</h3>
                    <p>Su consentimiento para el tratamiento de sus datos personales se recabará de manera explícita al momento de proporcionarlos. Usted podrá revocar su consentimiento en cualquier momento sin efectos retroactivos, siguiendo el procedimiento establecido en la sección de Derechos ARCO.</p>
                
                    <h3>9. Opciones y Medios para Limitar el Uso o Divulgación de Datos</h3>
                    <p>Si usted desea limitar el uso o divulgación de su información personal, puede enviar su solicitud al correo direccion@campusemp.com. En breve, le informaremos sobre los mecanismos para ejercer estas opciones.</p>
                    
                    <h3>10. Procedimientos y Medios por los cuales se Notificarán los Cambios al Aviso de Privacidad</h3>
                    <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir este aviso de privacidad, a través de nuestro sitio web www.campusemp.com.</p>
                
                    <h3>11. Seguridad de los Datos Personales</h3>
                    <p>Campus Emprendedor Idx se compromete a garantizar la protección de sus datos personales aplicando medidas de seguridad físicas, técnicas y administrativas para prevenir su pérdida, mal uso, alteración, acceso no autorizado y robo.</p>
                
                    <h3>12. Uso de Tecnologías de Rastreo en nuestro Portal de Internet</h3>
                    <p>Nuestro sitio web utiliza cookies y otras tecnologías para monitorear su comportamiento como usuario de Internet, mejorar su experiencia en nuestro sitio, y ofrecerles contenido adaptado a sus intereses. Usted puede desactivar estas tecnologías siguiendo las instrucciones de su navegador de Internet.</p>
                
                    <h3>13. Uso de Tecnologías de Rastreo en nuestro Portal de Internet</h3>
                    <p>Campus Emprendedor Idx se compromete a proteger la privacidad de los menores de edad. Por ello, la recopilación y tratamiento de sus datos se realiza siempre con el consentimiento de sus padres o tutores legales.</p>
                
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/acerca_campus">Acerca de CE</a></li>
                            <li><a routerLink="/aviso-privacidad">Aviso de Privacidad</a></li>
                            <li class="active"><a routerLink="/terminos-de-servicio">Terminos del Servicio</a></li>
                        </ul>
                    </section>
                    <!--
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                    -->
                </aside>
            </div>
        </div>
    </div>
</div>