<div class="page-title-area" style="background-color: #0f0f0f;">
    <div class="container">
        <div class="page-title-content">
            
            <h2 class="playfair-display-font" style="color: white;">Campus Emprendedor</h2>
        </div>
    </div>
    
</div>

<div class="features-area pt-100 pb-70">
    <app-features-style-two></app-features-style-two>
</div>

<div class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">POWER FOR THINKERS</span>
                    <h2>Minimiza el riesgo al Implementar tu Idea de Negocio</h2>
                    <p>En nuestro enfoque único, cada ‘Time’ atraviesa un proceso meticulosamente diseñado, comenzando con el Design Thinking y culminando en
                        resultados tangibles que optimizan tanto el tiempo como los recursos financieros de nuestros Thinkers. Este ciclo se caracteriza por su capacidad
                        de transformar ideas creativas en soluciones prácticas y eficientes.</p>
                    <p>¿Quieres saber más de nuestra metodología?</p>

                    <a href="assets/img/campus/documentos/presentacion_campusemp.pdf" download class="link-btn">Descarga el documento</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a data-lg-size="1280-720"
                           data-src="#"
                           data-sub-html="<p>Watch Video</p>"
                           class="d-block">
                            <div class="image">
                                <img src="assets/img/campus/index/campus_emprendedor.png" alt="image">
                            </div>
                            <!--
                            <div class="video-btn">
                                <i class="flaticon-play"></i>
                            </div>
                            -->
                        </a>
                    </lightgallery>
                    <div class="shape10"><img src="assets/img/campus/index/premio_emprendedor.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
 </div>
<!--
<app-feedback></app-feedback>
-->
<div class="bg-f9f9f9 pb-100">
    <app-instructor></app-instructor>
</div>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>
<!--
<div class="premium-access-area ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>
 -->
