import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ong-form',
  templateUrl: './ong-form.component.html',
  styleUrls: ['./ong-form.component.scss']
})
export class OngFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
