import { Component, OnInit } from '@angular/core';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
    selector: 'app-contact-preneur',
    templateUrl: './contact-preneur.component.html',
    styleUrls: ['./contact-preneur.component.scss']
})
export class ContactPreneurComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    bgImage = [
        {
            img: 'assets/img/campus/index/campus_emprendedor.png'
        }
    ]

    submit(form, e:Event){
        let name = form.name;
        console.log(name);

        let email = form.email;
        console.log(email);

        let edad = form.edad;
        console.log(edad);

        let genero = form.genero
        console.log(genero);

        let message = form.message;
        console.log(message);

        emailjs
      .sendForm('service_qgjsv8n',
        'template_9vkxrbn', e.target as HTMLFormElement, '9AHBNRq0Rss832Eto')
      .then(
        () => {
          console.log('SUCCESS!');
          name = '';
          email = '';
          edad = '';
          genero = '';
          message = '';
          alert('La soliocitud se ha enviado correctamente');
        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
      );
    }

}
