<div class="information-area ptb-20">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title"></span>
                    <h2></h2>
                    <ul class="apply-details">
                        <li>
                            <div class="icon">
                                <i class="flaticon-checkmark"></i>
                            </div>
                            <h3>Entorno IdeaPreneur </h3>
                            <p>
                                En este taller exploraremos uno de los entornos clave de Campus Emprendedor, 
                                enfocado en la fase inicial y fundamental de cualquier emprendimiento.<br>
                                Se centra en el proceso crítico de validar y refinar una idea de 
                                negocio original. Este entorno es clave para aquellos Thinkers que 
                                inician con una concepción novedosa, pero aún no han probado su idea 
                                en el mercado real. 
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-webinar"></i>
                            </div>
                            <h3>Ventana de oportunidad</h3>
                            <p>
                                Esta es tu oportunidad para romper barreras, perder el miedo y descubrir nuevas 
                                posibilidades para llevar tu idea al siguiente nivel. Únete a nosotros y conviértete
                                 en uno de los actores de cambio que construyen el futuro empresarial de nuestra 
                                 región. Potencializaremos:<br>
                                <br>
                                Ideas de innovación <br>
                                Ideas  de impactos social <br>
                                Ideas de sustentabilidad <br>
                                Ideas “low cost” <br>

                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Resultados esperados</h3>
                            <p>
                                Hoja de ruta de investigación de la idea de negocio <br>
                                Estructura de validación de la idea <br>
                                Know How de análisis de idea de negocio. <br>

                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title"></span>
                    <h2></h2>
                    <ul class="apply-details">
                        
                        <li>
                            <div class="icon">
                                <i class="flaticon-credit-card-1"></i>
                            </div>
                            <h3>Acceso libre y gratuito</h3>
                            <p>
                                Como parte de un convenio entre El Centro de Innovación, Emprendimiento y 
                                Negocios (CIEN) del Estado de Puebla en colaboración con Campus Emprendedor ® 
                                Power for Thinker, el entorno será completamente gratis. Solo debes llenar 
                                el formato y aceptar las horarios establecidos para el curso.
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Temario</h3>
                            <p>
                                Entorno IdeaPreneur <br>
                                <br>
                                1.	Validad tu idea de negocio 	<br>
                                2.	Manos a la Obra (Desarrollo Producto/Servicio)	<br>
                                3.	De la idea a la realidad (Creación de prototipos)	<br>
                                4.	Alcance de mercado (Valida a tu cliente)	<br>
                                5.	Versión de salida (Producto Mínimo Viable)	<br>
                            </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Días y horarios</h3>
                            <p>
                                Capacitación intensiva 2024 <br>
                                <br>
                                20 de noviembre de 9:00 a.m. - 2:00 p.m. 	<br>
                                22 de noviembre de 9:00 a.m. - 2:00 p.m.	<br>
                                25 de noviembre de 9:00 a.m. - 2:00 p.m.	<br>
                                27 de noviembre de 9:00 a.m. - 2:00 p.m.	<br>
                                29 de noviembre de 9:00 a.m. - 2:00 p.m.	<br>
                            </p>
                        </li>
                    </ul>
                </div>
                <!--
                <div class="information-image text-center">
                
                   <img src="assets/img/campus/index/aplicar_ce.png" alt="image">
                </div>
                -->
            </div>
        </div>
    </div>
</div>
