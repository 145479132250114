import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-bloque-humano',
  templateUrl: './courses-bloque-humano.component.html',
    styleUrls: ['./courses-bloque-humano.component.scss']
})
export class CourseBloqueHumano implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
