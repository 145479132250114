<div class="our-story-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number"></span> Que ofrecemos</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>
                        Una sólida colaboración y alianza estratégica para trabajar de la mano en la creación y
                        mantenimiento de entornos de competencia saludables. Nuestro enfoque se centra en el desarrollo conjunto de
                        políticas y programas que promuevan la innovación, el emprendimiento y la equidad económica. Trabajamos en estrecha
                        colaboración con su administración para fortalecer la economía, estimular la creación de empleo y fomentar la
                        inversión, garantizando así un futuro próspero para la comunidad y el país.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">1</span> Laboratorios de Emprendimiento</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>
                        Programas de incubación y aceleración diseñados específicamente para brindar apoyo a startups y emprendedores en etapas tempranas. Nuestra oferta evoluciona en función del entorno empresarial actual, con el objetivo de minimizar los riesgos asociados con la implementación de proyectos. Estamos comprometidos en establecer cadenas de ayuda sólidas que faciliten rutas empresariales de bajo costo, trabajando en estrecha colaboración con los gobiernos para respaldar la creación y el crecimiento de empresas locales.
                    </p>
                    
                </div>
            </div>
            <!--
            <div class="col-lg-12 col-md-12">
                <div class="our-story-image">
                    <img src="assets/img/story-img.jpg" alt="image">
                </div>
            </div>
                -->

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">2</span> Colaboración en Herramientas para Políticas Públicas</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Colaboración cercana con el gobierno para co-crear herramientas, informes y datos fundamentales que respalden la formulación y evaluación de políticas públicas relacionadas con el emprendimiento
                        <br>Utilizamos inteligencia de negocios generada por nuestras plataformas y laboratorio de información para evaluar y analizar datos relevantes
                        <br>Estamos comprometidos en colaborar en la creación de estructuras que impulsen mejoras en el entorno de los emprendedores, lo que se traduce en políticas públicas más efectivas y un ecosistema empresarial más sólido

                    </p>
                 </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">3</span> Programas de Formación en Zonas de Alto Impacto</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>

                        Programas de formación diseñados específicamente para zonas de alto impacto. Identificamos áreas geográficas con necesidades especiales o desafíos socioeconómicos y desarrollamos programas adaptados a esas comunidades. Nuestra prioridad es brindar modelos de implementación que minimicen el riesgo operativo y de inversión, permitiendo a emprendedores y empresas locales prosperar en entornos desafiantes y contribuir al desarrollo económico sostenible de estas zonas.

                    </p>
                   
                </div>
            </div>
        </div>
    </div>
</div>
