<div class="lifestyle-area bg-f6f7fb pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">Inicia Tu Viaje: <br>Elige Tu Entorno de Emprendimiento</h2>
            
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <!-- <i class="flaticon-diet"></i> -->
                        <img src="assets/img/campus/index/ideapreneur.png" alt="Logotipo de Entorno Validación de Ideas de Negocio">
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/entorno-idea-de-negocio">IDEAPRENEUR</a></h3>
                    <p>Evalúa el riesgo y da vida a tus ideas.<br>
                    ¿Tienes una idea de negocio o proyecto, sin experiencia 
                    previa en la gestión empresarial o validación en el mercado?
                    </p>
                    <a routerLink="/entorno-idea-de-negocio" class="link-btn">Vamos, pierde tus miedos</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                        <!-- <i class="flaticon-vitamin-c"></i> -->
                        <img src="assets/img/campus/index/solopreneur.png" alt="Logotipo de Entorno de Autoempleo">
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/entorno-autoempleo">SOLOPRENEUR</a></h3>
                    <p>Lleva tu idea de negocio al siguiente nivel.<br> 
                        ¿Estás por iniciar o emprendiste una idea de negocio? 
                        Potencializa tus recursos para impactar en el mercado. 
                    </p>
                    <a routerLink="/entorno-autoempleo" class="link-btn">Vamos, sigue con seguridad</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-lifestyle-box">
                    <div class="icon">
                      <!--  <i class="flaticon-heart-rate-monitor"></i> -->
                      <img src="assets/img/campus/index/inverpreneur.png" alt="Logotipo de Entorno de Evaluación de Inversión">
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/entorno-inversion">INVERPRENEUR</a></h3>
                    <p>La mejor herramienta para medir riesgos.<br>
                        ¿Dispones de los recursos financieros o los activos 
                        necesarios para poner en marcha una idea de negocio? 
                       </p>
                    <a routerLink="/entorno-inversion" class="link-btn">Vamos, camina sin riesgos</a>
                </div>
            </div>
        </div>
    </div>
</div>