<div class="courses-area pt-100 pb-70 bg-f5f7fa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title"> Inicia tu emprendimiento</span>
            <h2>Nuestros Entornos de Emprendimiento</h2>
            <p>
                En los "Entornos de Emprendimiento", una amplia gama de emprendedores, inversores, expertos y
                recursos convergen para dar vida a ideas y oportunidades. Cada tipo de emprendimiento, desde la
                concepción de nuevas ideas de negocio hasta la expansión de modelos existentes, encuentra su
                lugar en este ecosistema.
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-language-courses-box">
                    <img src="assets/img/campus/index/ce_inversion.png" alt="Campus Emprendedor - Emprendimiento por Inversión">
                    <h3>Inversión</h3>
                    <p>
                        Dispones de los recursos financieros y los activos necesarios para poner en marcha una idea de negocio.

                    </p>
                    <a routerLink="/entorno-inversion" class="default-btn"><i class="flaticon-right"></i>Conocer más<span></span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-language-courses-box">
                    <img src="assets/img/campus/index/ce_autoempleo.png" alt="Campus Emprendedor - Emprendimiento por Autoempleo">
                    <h3>Autoempleo</h3>
                    <p>
                        Tienes habilidades, experiencia y conocimientos especializados, con el objetivo de proporcionar un producto o servicio específico al mercado.
                    </p>
                    <a routerLink="/entorno-autoempleo" class="default-btn"><i class="flaticon-right"></i>Conocer más<span></span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-language-courses-box">
                    <img src="assets/img/campus/index/ce_idea.png" alt="Campus Emprendedor - Emprendimiento por Idea de Negocio">
                    <h3>Idea de Negocio</h3>
                    <p>
                        Tienes una idea de  negocio o proyecto, sin experiencia previa en la gestión empresarial o  validación en el mercado.

                    </p>
                    <a routerLink="/entorno-idea-de-negocio" class="default-btn"><i class="flaticon-right"></i>Conocer más<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>
